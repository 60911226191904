import React, { ChangeEvent, useCallback, useRef } from 'react'
import style from './style.less'
import classNames from 'classnames'

const GridCheckboxElement = ({
  checked,
  onChange,
  idx,
  id,
}: {
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  idx: number
  id: string
}) => {
  const onMouseOver = useCallback((idx: number) => {
    const row = [...document.querySelectorAll(`.gridItem${idx}`)]
    row.map((r) => r.classList.add(style.hoverRow))
  }, [])

  const onMouseLeave = useCallback((idx: number) => {
    const row = [...document.querySelectorAll(`.gridItem${idx}`)]
    row.map((r) => r.classList.remove(style.hoverRow))
  }, [])

  return (
    <div
      className={classNames(
        style.gridItem,
        'gridItemSelector',
        `gridItem${idx}`,
        style.itemCheckbox
      )}
      onMouseOver={() => onMouseOver(idx)}
      onMouseLeave={() => onMouseLeave(idx)}
    >
      <input type="checkbox" checked={checked} value={id} onChange={onChange} />
    </div>
  )
}

export interface IGridSelection {
  ids: string[]
  selectedIds: string[]
  onSelection: (ids: string[]) => void
}

const GridSelection = ({ ids, selectedIds, onSelection }: IGridSelection) => {
  const headerCheckboxRef = useRef<HTMLInputElement>(null)

  const onItemChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: id, checked } = e.target
    const newIds = checked
      ? [...selectedIds, id]
      : selectedIds.filter((i) => i !== id)
    onSelection(newIds)
    if (headerCheckboxRef.current) {
      headerCheckboxRef.current.checked = newIds.length === ids.length
      headerCheckboxRef.current.indeterminate =
        newIds.length !== 0 && newIds.length !== ids.length
    }
  }

  const onAllCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    onSelection(checked ? [...ids] : [])
  }

  return (
    <div className={classNames(style.gridCol, style.gridSelection)}>
      <div className={classNames(style.inline)}>
        <div
          className={classNames(
            style.gridItemHeader,
            `gridItem`,
            style.headerCheckbox
          )}
        >
          <input
            ref={headerCheckboxRef}
            type="checkbox"
            onChange={onAllCheckboxChange}
          />
        </div>
      </div>
      {ids.map((id, i) => (
        <div key={i} className={classNames(style.inline)}>
          <GridCheckboxElement
            idx={i + 1}
            id={id}
            checked={selectedIds.includes(id)}
            onChange={onItemChange}
          />
        </div>
      ))}
    </div>
  )
}

export default GridSelection
