import style from './style.less'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import { SEARCH_ALL_FILTERS_ID } from './consts'
import React, { ChangeEvent, memo } from 'react'
import classNames from 'classnames'

interface FilterSearchProps {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  tag?: boolean
}

const FilterSearch = ({ value, onChange, tag }: FilterSearchProps) => {
  return (
    <div
      className={classNames(
        style.searchContainer,
        tag ? style.searchContainerTag : undefined
      )}
    >
      <div className={style.searchInput}>
        <Icon name="Search" version={Version.v2} />
        <input
          id={SEARCH_ALL_FILTERS_ID}
          placeholder="Search all"
          value={value}
          onChange={onChange}
        />
      </div>
      {value && (
        <Icon
          name="Close"
          version={Version.v2}
          className={style.closeIcon}
          onClick={onChange}
        />
      )}
    </div>
  )
}

export default memo(FilterSearch)
