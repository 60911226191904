import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import style from './style.less'
import { FilterValuesMatchingInputProps, RANGE_NUM_REGEX } from './consts'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import { Version } from '../types'

export interface FilterMenuInputTagProps {
  tagKey: string
  input: FilterValuesMatchingInputProps
}

const FilterMenuInputTag = ({ tagKey, input }: FilterMenuInputTagProps) => {
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)
  const [, operator] = tag ? tag.value.split(' ') : []
  const [inputValue, setInputValue] = useState('')
  const [minInputValue, setMinInputValue] = useState('')
  const [maxInputValue, setMaxInputValue] = useState('')
  const [newFullTag, setNewFullTag] = useState('')

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (tag) {
        const inputValue = e.target.value
        const queryParamToEdit = tag.value.split(' ').filter((t) => t !== '')
        if (queryParamToEdit) {
          const ifAlreadyExists = queryParamToEdit.length > 2
          const newTag = [...queryParamToEdit]
          ifAlreadyExists ? (newTag[2] = inputValue) : newTag.push(inputValue)
          setNewFullTag(newTag.join(' '))
          setInputValue(inputValue)
        }
      }
    },
    [tag]
  )
  const onMinChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMinInputValue(e.target.value)
  }, [])

  const onMaxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMaxInputValue(e.target.value)
  }, [])

  useEffect(() => {
    if (operator !== 'x-y') return
    const val = `${minInputValue}-${maxInputValue}`
    if (tag && val.match(RANGE_NUM_REGEX)) {
      const queryParamToEdit = tag.value.split(' ').filter((t) => t !== '')
      if (queryParamToEdit) {
        const [start, operator] = queryParamToEdit
        const newTag = `${start} ${operator} ${val || ''}`
        setNewFullTag(newTag)
      }
    }
  }, [minInputValue, maxInputValue, tag, operator])

  useEffect(() => {
    const timeout = setTimeout(() => {
      const [, oldOperator, oldVal] = tag?.value?.split(' ') || []
      const [, newOperator, newVal] = newFullTag?.split(' ') || []
      if (
        tags &&
        newFullTag &&
        tag &&
        oldVal !== newVal &&
        oldOperator === newOperator
      ) {
        tag.value = newFullTag
        update(tags.filter((t) => (t.key === tagKey ? newFullTag : t)))
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [newFullTag, tags, tagKey, tag])

  useEffect(() => {
    if (tag) {
      if (operator === 'x-y') {
        const [min, max] = tag.value.split(' ')[2].split('-')
        min && setMinInputValue(min)
        max && setMaxInputValue(max)
      } else {
        setInputValue(tag.value.split(' ')[2])
      }
    }
  }, [tag, operator])

  if (operator === 'x-y') {
    return (
      <div className={style.minMaxContainer}>
        <div>
          <Typography
            type={TypographyTypes.label}
            size={TypographySizes.small}
            version={Version.v2}
            color="color-neutral-040"
          >
            MIN. VALUE
          </Typography>
          <div className={style.filterMenuInputContainer}>
            <input
              className={style.filterMenuInput}
              value={minInputValue}
              placeholder={input.placeholder}
              onChange={onMinChange}
            />
            <Typography type={TypographyTypes.label}>{tag?.unit}</Typography>
          </div>
        </div>
        <div>
          <Typography
            type={TypographyTypes.label}
            size={TypographySizes.small}
            version={Version.v2}
            color="color-neutral-040"
          >
            MAX. VALUE
          </Typography>
          <div className={style.filterMenuInputContainer}>
            <input
              className={style.filterMenuInput}
              value={maxInputValue}
              placeholder={input.placeholder}
              onChange={onMaxChange}
            />
            <Typography type={TypographyTypes.label}>{tag?.unit}</Typography>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={style.filterMenuInputContainer}>
      <input
        className={style.filterMenuInput}
        value={inputValue}
        placeholder={input.placeholder}
        onChange={onChange}
      />
      <Typography type={TypographyTypes.label}>{tag?.unit}</Typography>
    </div>
  )
}

export default memo(FilterMenuInputTag)
