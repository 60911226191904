// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__container--jLnxO {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 0.25rem;\n  background-color: #f1f4f2;\n  position: relative;\n  border-radius: 0.25rem;\n}\n.style__progression--MGieg {\n  height: 0.25rem;\n  background-color: #00a751;\n  left: 0;\n  position: absolute;\n  border-radius: 0.25rem;\n}\n", "",{"version":3,"sources":["webpack://./../components/src/Progression/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;AAHJ;AANA;EAaI,eAAA;EACA,yBAAA;EACA,OAAA;EACA,kBAAA;EACA,sBAAA;AAJJ","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: @spacing-25;\n    background-color: @color-pewter-grey-095;\n    position: relative;\n    border-radius: @spacing-25;\n  }\n\n  .progression {\n    height: @spacing-25;\n    background-color: @color-green-060;\n    left: 0;\n    position: absolute;\n    border-radius: @spacing-25;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style__container--jLnxO",
	"progression": "style__progression--MGieg"
};
export default ___CSS_LOADER_EXPORT___;
