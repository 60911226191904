import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import style from './style.less'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import { Popper } from '@mui/material'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import FilterMenuMultiTag from './FilterMenuMultiTag'
import FilterMenuInputTag from './FilterMenuInputTag'
import {
  FILTER_VALUES_MATCHING_INPUT,
  FILTER_VALUES_MATCHING_INPUT_KEYS,
  FILTER_VALUES_MATCHING_MULTI,
  FILTER_VALUES_MATCHING_MULTI_KEYS,
  FilterValuesMatchingInputProps,
} from './consts'
import { findMatchingValues } from './utils'
import OperatorSelect from './OperatorSelect'
import FilterMenuDateTag from './FilterMenuDateTag'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import { useSearchParams } from 'react-router-dom'
import FilterSearch from './FilterSearch'

export interface FilterMenuTagProps {
  tagKey: string
  open: boolean
  setOperatorsRef: any
}

interface TagType {
  hasInput: FilterValuesMatchingInputProps
  hasMulti: string[]
  hasDate: boolean
}

const FilterMenuTag = forwardRef(function FilterMenuMulti(
  { tagKey, open, setOperatorsRef }: FilterMenuTagProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState<string>('')
  const [types, setTypes] = useState<TagType>({
    hasInput: {
      placeholder: '',
    },
    hasMulti: [],
    hasDate: false,
  })
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)

  const deleteTag = useCallback(() => {
    tags && update(tags.filter((t) => t.key !== tagKey))
  }, [tags, update, searchParams, tagKey])

  useEffect(() => {
    tag &&
      setTypes({
        hasMulti:
          FILTER_VALUES_MATCHING_MULTI[
            findMatchingValues(
              FILTER_VALUES_MATCHING_MULTI,
              tag.value as string
            ) as FILTER_VALUES_MATCHING_MULTI_KEYS
          ],
        hasInput:
          FILTER_VALUES_MATCHING_INPUT[
            findMatchingValues(
              FILTER_VALUES_MATCHING_INPUT,
              tag.value as string
            ) as FILTER_VALUES_MATCHING_INPUT_KEYS
          ],
        hasDate: tag.value.toString().includes('date ='),
      })
  }, [tag])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '')
  }

  const multi = useMemo(
    () =>
      search
        ? [...types.hasMulti].filter((m) =>
            m.toLowerCase().includes(search.toLowerCase())
          )
        : types.hasMulti,
    [search, types.hasMulti]
  )

  return (
    tag && (
      <Popper
        className={style.filterMenuMulti}
        anchorEl={(ref as any)?.current}
        disablePortal
        open={open}
        style={{ zIndex: 1 }}
        placement={'bottom-start'}
      >
        <div className={style.filterMenuMultiHeader}>
          <div className={style.filterMenuMultiHeaderValue}>
            <Typography
              type={TypographyTypes.body}
              size={TypographySizes.small}
            >
              {tag.value.split(' ')[0]}
            </Typography>
            <OperatorSelect tagKey={tagKey} setOperatorsRef={setOperatorsRef} />
          </div>
          <Icon
            name={'Delete'}
            version={Version.v2}
            color={'new-color-red-050'}
            className={style.deleteIcon}
            onClick={deleteTag}
          />
        </div>
        <div className={style.filterMenuMultiTags}>
          {types.hasMulti && (
            <>
              {types.hasMulti.length > 10 && (
                <FilterSearch
                  value={search}
                  onChange={handleSearchChange}
                  tag={true}
                />
              )}
              <FilterMenuMultiTag tagKey={tagKey} multi={multi} />
            </>
          )}
          {types.hasInput && (
            <FilterMenuInputTag tagKey={tagKey} input={types.hasInput} />
          )}
          {types.hasDate && <FilterMenuDateTag tagKey={tagKey} />}
        </div>
      </Popper>
    )
  )
})

export default memo(FilterMenuTag)
