import { useNavigate, useParams } from 'react-router-dom'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import {
  getPartners,
  Partner,
  updatePartner,
  getSessions,
  Session,
  getUsers,
  UsersResponse,
  getVehicles,
  VehicleResponse,
  CreateVehicle,
  updateVehicle,
  createVehicle,
} from '@yaak/components/services/api/api'
import style from './style.less'
import appStyle from '../../style.less'
import {
  getLanguageComponent,
  getLanguageFullName,
} from '../../helpers/language'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Tabs from '@yaak/components/src/Tabs'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'
import Grid from '@yaak/components/src/Grid'
import Button from '@yaak/components/src/Button'
import Typography from '@yaak/components/src/Typography'
import Badge from '@yaak/components/src/Badge'
import Icon from '@yaak/components/src/Icon'
import Tooltip from '@yaak/components/src/Tooltip'
import UserOverview from '../UserOverview/UserOverview'
import {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import SearchBar from '@yaak/components/src/SearchBar'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import {
  DS_ADMINS_IN_PARTNERS_HEADERS,
  INSTRUCTORS_IN_PARTNERS_HEADERS,
  STUDENTS_IN_PARTNERS_HEADERS,
} from '../../helpers/users'
import {
  editVehicleAction,
  getMappedVehicles,
  HEADERS_WITHOUT_PARTNER,
} from '../../helpers/vehicles'
import { EditVehicleDialog } from '../VehicleOverview/VehicleDialogs'
import PartnerEditDialog from '../PartnerOverview/dialogs/PartnerEditDialog/PartnerEditDialog'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import { useTabIndex } from '@yaak/components/customHooks/useTabIndex'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import SimulatorRigsOverview from '@yaak/components/src/SimulatorRigsOverview'
import { useSmallScreenMatches } from '@yaak/components/customHooks/useSmallScreenMatches'
import { useAuth0 } from '@auth0/auth0-react'
import { isSimAdmin } from '../../utils/simAdmin'
import SimulationOverview from '../SimulationOverview'

interface PartnerPageProps {
  token: string
}

const HEADER_SORT = ['License Plate', 'VIN', 'Kit ID', 'Calibration data']

const sortValue = [
  'licensePlate',
  'vin',
  '',
  'dongleId',
  'calibrationAvailable',
]

const tabsMapping: Record<number, string> = {
  0: 'drives',
  1: 'dsadmins',
  2: 'instructors',
  3: 'students',
  4: 'vehicles',
  5: 'simulatorrigs',
}

const PartnerPage: React.FunctionComponent<PartnerPageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [loading, setLoading] = useState(true)
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null)
  const [partner, setPartner] = useState<Partner | null>({} as Partner)
  const [instructors, setInstructors] = useState<UsersResponse>()
  const [students, setStudents] = useState<UsersResponse>()
  const [admins, setAdmins] = useState<UsersResponse>()
  const [sessions, setSessions] = useState<Session>({} as Session)
  const [vehicles, setVehicles] = useState<VehicleResponse>()
  const [getMoreData, setGetMoreData] = useState<boolean>()
  const [fetchMoreData, setFetchMoreData] = useState<{
    response?: any
    role?: string
    setter?: any
  }>()
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()
  const [selectedVehicle, setSelectedVehicle] = useState<CreateVehicle>()
  const [partners, setPartners] = useState<Partner[]>([])
  const [creatingVehicle, setCreatingVehicle] = useState<boolean>(false)

  const { partnerId, tab } = useParams()

  const navigate = useNavigate()
  const matches = useSmallScreenMatches()

  const currentTab = useTabIndex(tabsMapping, tab)

  const { user } = useAuth0()
  const simAdmin = isSimAdmin(user)

  const fetchVehicles = async () => {
    const vehicles = await getVehicles({
      token,
      onAlert: setShowToast,
      searchQuery,
      partnerId,
      sortBy,
      sortOrder,
    })

    setVehicles(vehicles)
    setLoading(false)
  }

  useEffect(() => {
    currentTab === 0 &&
      setFetchMoreData({
        response: sessions,
        role: 'yaak-admin',
        setter: setSessions,
      })
    currentTab === 1 &&
      setFetchMoreData({
        response: admins,
        role: 'ds-admin',
        setter: setAdmins,
      })
    currentTab === 2 &&
      setFetchMoreData({
        response: instructors,
        role: 'ds-instructor',
        setter: setInstructors,
      })
    currentTab === 3 &&
      setFetchMoreData({
        response: students,
        role: 'ds-student',
        setter: setStudents,
      })
  }, [currentTab, sessions, students, instructors, admins])

  useEffect(() => {
    const fetchData = async () => {
      const [instructors, students, admins, partners] = await Promise.all([
        getUsers({
          token,
          onAlert: setShowToast,
          roles: ['ds-instructor'],
          partnerId,
        }),
        getUsers({
          token,
          onAlert: setShowToast,
          roles: ['ds-student'],
          partnerId,
        }),
        getUsers({
          token,
          onAlert: setShowToast,
          roles: ['ds-admin'],
          partnerId,
        }),
        getPartners({ token, onAlert: setShowToast }),
      ])

      if (!simAdmin && currentTab === 0) {
        const sessions = await getSessions({
          token,
          onAlert: setShowToast,
          partnerId,
          searchQuery,
          sortBy,
          sortOrder,
        })

        setSessions(sessions)
      }

      setInstructors(instructors)
      setStudents(students)
      setAdmins(admins)
      const currentPartner = partners.find(
        (partner) => partner.id === partnerId
      )
      setPartners(partners)
      currentPartner && setPartner(currentPartner)
      !simAdmin && (await fetchVehicles())
      setLoading(false)
    }
    token && fetchData()
  }, [token, searchQuery, sortBy, sortOrder])

  useEffect(() => {
    fetchMoreData && getMoreData && fetchData2()
    setGetMoreData(false)
  }, [fetchMoreData, getMoreData])

  const fetchData2 = async () => {
    const morePages =
      fetchMoreData?.response?.data.length &&
      fetchMoreData.response.data.length <
        fetchMoreData.response.metaData.totalCount

    if (
      morePages &&
      fetchMoreData &&
      fetchMoreData.role &&
      fetchMoreData.response
    ) {
      if (!simAdmin && currentTab === 0) {
        const newSessions = await getSessions({
          token,
          onAlert: setShowToast,
          partnerId,
          searchQuery,
          sortBy,
          sortOrder,
        })
        const newData = {
          ...fetchMoreData.response,
          data: [...fetchMoreData.response.data, ...newSessions.data],
        }
        if (newSessions) {
          fetchMoreData.setter(newData)
          setFetchMoreData({
            response: newData,
            role: fetchMoreData.role,
            setter: fetchMoreData.setter,
          })
        }
      } else {
        const newStudents = await getUsers({
          token,
          onAlert: setShowToast,
          roles: [fetchMoreData.role],
          partnerId,
          offset: fetchMoreData.response.data.length,
        })

        if (newStudents) {
          const newData = {
            ...fetchMoreData.response,
            data: [...fetchMoreData.response.data, ...newStudents.data],
          }
          fetchMoreData.setter({
            ...fetchMoreData.response,
            data: [...fetchMoreData.response.data, ...newStudents.data],
          })
          setFetchMoreData({
            response: newData,
            role: fetchMoreData.role,
            setter: fetchMoreData.setter,
          })
        }
      }
    }
  }

  const onVehicleClicked = (rowIndex: number) => {
    navigate(`/vehicles/${vehicles?.data[rowIndex].vin}`)
  }

  const onEditPartner = async (partner: Partner) => {
    const partnerToUpdate = {
      ...selectedPartner,
      ...partner,
    }

    setSelectedPartner(null)

    const updatedPartner = await updatePartner({
      token,
      partner: {
        id: partnerToUpdate.id,
        name: partnerToUpdate.name,
        language: partnerToUpdate.language,
        mainContact: null,
        mainContactId: partnerToUpdate.mainContactId,
        simEnabled: partnerToUpdate.simEnabled,
      },
      onAlert: setShowToast,
    })
    setPartner(updatedPartner)
    setSelectedPartner(null)
  }

  const onUpdateVehicle = async (vehicle?: CreateVehicle) => {
    if (vehicle) {
      await updateVehicle({ token, vehicle, onAlert: setShowToast })
      setSelectedVehicle(undefined)
      await fetchVehicles()
    }
  }

  const onCreateVehicle = async (vehicle?: CreateVehicle) => {
    if (vehicle) {
      await createVehicle({ token, vehicle, onAlert: setShowToast })
      setCreatingVehicle(false)
      await fetchVehicles()
    }
  }

  return (
    <div className={appStyle.page}>
      {loading ? (
        <ProgressBar />
      ) : (
        <div className={style.partnerDataContainer}>
          <Breadcrumbs
            first={{
              text: 'Partners',
              url: '/Partners',
            }}
            second={{
              text: partner?.name || '',
            }}
          />
          <div className={style.partnerDetailsContainer}>
            <div className={style.partnerDetailsHeader}>
              <Typography
                type={TypographyTypes.headline}
                size={TypographySizes.small}
              >
                {partner?.name}
              </Typography>
              <Tooltip text={'Copy partner ID'} position={'top'}>
                <Icon
                  name={'Copy'}
                  size={IconSizes.medium}
                  className={style.icon}
                  onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                    event.stopPropagation()
                    if (partner) {
                      navigator.clipboard.writeText(partner.id)
                      setShowToast({
                        text: `Partner ID copied to clipboard`,
                      })
                    }
                  }}
                />
              </Tooltip>
            </div>
            <Button
              onClick={() => {
                setSelectedPartner(partner)
              }}
              icon={<Icon name={'Edit'} />}
              text={'Edit'}
              secondary
            />
          </div>
          <div className={style.partnerDetailsBadges}>
            <Badge label={'Active'} withDot={true} />
            {partner?.mainContact && (
              <Badge
                type={BadgeType.grey}
                label={`${partner.mainContact.firstName} ${partner.mainContact.lastName}`}
                icon={partner.mainContact.profileImageURL}
              />
            )}
            {partner?.simEnabled && (
              <Badge
                type={BadgeType.grey}
                label={'Sim-enabled'}
                icon={<Icon name={'VR'} />}
              />
            )}
            <Badge
              type={BadgeType.grey}
              label={getLanguageFullName(partner?.language)}
              icon={getLanguageComponent(partner?.language)}
            />
          </div>
          <Divider />
        </div>
      )}
      <Tabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          navigate(`/partners/${partnerId}/${tabsMapping[tab]}`)
        }}
        tabs={[
          {
            index: 0,
            text: 'Drives',
            children: simAdmin ? (
              <SimulationOverview
                token={token}
                withHeader={false}
                partnerId={partnerId}
                withDriver={false}
                showAll={true}
              />
            ) : (
              <DrivesOverview
                token={token}
                filter={{ partnerId }}
                withHeader={false}
                withPartner={false}
              />
            ),
          },
          {
            index: 1,
            text: 'DS Admins',
            children: (
              <UserOverview
                headers={DS_ADMINS_IN_PARTNERS_HEADERS}
                token={token}
                role="ds-admin"
                partnerId={partnerId}
                options={{
                  fixedColumns: 3,
                  withHeader: false,
                  withPartner: false,
                  searchPlaceholder:
                    'Search DS Admin names, emails, mobile numbers and IDs',
                }}
              />
            ),
          },
          {
            index: 2,
            text: 'Instructors',
            children: (
              <UserOverview
                headers={INSTRUCTORS_IN_PARTNERS_HEADERS}
                token={token}
                role="ds-instructor"
                partnerId={partnerId}
                options={{
                  fixedColumns: 3,
                  smallColumns: [0],
                  searchPlaceholder:
                    'Search instructor names, emails, mobile numbers and IDs',
                  withHeader: false,
                  withPartner: false,
                  withInstructorAdmin: true,
                }}
              />
            ),
          },
          {
            index: 3,
            text: 'Students',
            children: (
              <UserOverview
                headers={STUDENTS_IN_PARTNERS_HEADERS}
                token={token}
                role="ds-student"
                partnerId={partnerId}
                options={{
                  fixedColumns: 3,
                  searchPlaceholder:
                    'Search student names, emails, mobile numbers and IDs',
                  withHeader: false,
                  withPartner: false,
                  withToS: true,
                }}
              />
            ),
          },
          simAdmin
            ? null
            : {
                index: 4,
                text: 'Vehicles',
                childrenTop: (
                  <div className={style.searchFilter}>
                    <SearchBar
                      clear
                      fullWidth
                      placeholder={'Search license plates, VINs and kits'}
                      setSearchQuery={setSearchQuery}
                    />
                    <Button
                      onClick={() => setCreatingVehicle(true)}
                      text={'Add vehicle'}
                    />
                  </div>
                ),
                children:
                  vehicles && vehicles.data?.length > 0 ? (
                    <Grid
                      headers={HEADERS_WITHOUT_PARTNER}
                      data={getMappedVehicles(
                        vehicles.data,
                        navigate,
                        matches,
                        {
                          onEdit: (vin) =>
                            editVehicleAction({
                              vin,
                              vehicles,
                              setSelectedVehicle,
                            }),
                        },
                        { withPartner: false }
                      )}
                      onRowClick={onVehicleClicked}
                      headerSort={HEADER_SORT}
                      onHeaderClicked={(props) => {
                        setSortBy(sortValue[props.index])
                        setSortOrder(
                          props.sortOrder[props.index] ? 'DESC' : 'ASC'
                        )
                      }}
                    />
                  ) : (
                    <div>No active vehicles.</div>
                  ),
              },
          {
            index: 5,
            text: 'Simulator rigs',
            children: (
              <SimulatorRigsOverview
                token={token}
                withHeader={false}
                partnerId={partnerId}
                withPartner={false}
              />
            ),
          },
        ]}
      />
      <EditVehicleDialog
        isOpen={!!selectedVehicle || creatingVehicle}
        vehicle={selectedVehicle}
        partners={partners && Object.values(partners)}
        onCancel={() => {
          setSelectedVehicle(undefined)
          setCreatingVehicle(false)
        }}
        onSave={creatingVehicle ? onCreateVehicle : onUpdateVehicle}
      />
      <PartnerEditDialog
        simAdmin={simAdmin}
        token={token}
        selectedPartner={selectedPartner}
        onCancel={() => setSelectedPartner(null)}
        onConfirmed={onEditPartner}
      />
    </div>
  )
}

export default PartnerPage
