import React, { memo } from 'react'
import classNames from 'classnames'
import style from './style.less'
import Typography, { TypographyTypes } from '../Typography'
import Icon from '../Icon'
import { IconTypes } from '../Icon/Icon'
import { Version } from '../types'
import { titleCase } from '../SearchQueryBar/utils'

interface GridHeaderElementProps {
  icon: boolean
  version?: Version
  sortOrder: boolean | null
  column: any
  sortEnabled: boolean
  align?: string
}

interface SortCaretElementProps {
  sortOrder: boolean | null
  version?: Version
}

const SortCaretElement = ({ sortOrder, version }: SortCaretElementProps) => {
  return (
    <div className={style.sortCaretButton}>
      {sortOrder ? (
        <Icon
          name={version === Version.v2 ? 'ArrowDown' : 'ArrowDownward'}
          type={IconTypes.regular}
          version={version}
          className={version === Version.v2 ? style.sortIcon : undefined}
        />
      ) : (
        <Icon
          className={classNames(
            version === Version.v2 ? style.sortIcon : undefined,
            version !== Version.v2 ? style.caretDown : undefined
          )}
          name={version === Version.v2 ? 'ArrowUp' : 'ArrowDownward'}
          type={IconTypes.regular}
          version={version}
        />
      )}
    </div>
  )
}

const GridHeaderElement = ({
  sortOrder,
  column,
  icon,
  sortEnabled,
  version,
  align,
}: GridHeaderElementProps) => {
  return (
    <div
      className={classNames(
        style.gridItemHeader,
        align ? style[`gridItemHeader${titleCase(align)}`] : undefined,
        sortEnabled ? style.pointer : undefined,
        icon ? style.gridItemCenter : undefined,
        version === Version.v2 ? style.gridItemHeaderV2 : undefined
      )}
    >
      <Typography type={TypographyTypes.label} version={version}>
        {column.text || column}
      </Typography>
      {sortEnabled && (
        <SortCaretElement sortOrder={sortOrder} version={version} />
      )}
    </div>
  )
}

export default memo(GridHeaderElement)
