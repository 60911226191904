import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns'

const HOUR_MS = 1000 * 60
export const toHoursAndMinutes = (totalMinutes: number | undefined) => {
  if (totalMinutes !== undefined) {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = Math.floor(totalMinutes % 60)
    return `${hours}h ${minutes.toString().padStart(2, '0')}m`
  }
}
export const toHoursMinutesSeconds = (ms: number | undefined) => {
  if (ms !== undefined) {
    const seconds = Math.floor(ms / 1000) % 60
    const minutes = Math.floor(ms / 60 / 1000) % 60
    const hours = Math.floor(ms / 3600 / 1000)
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
}

export const toHours = (ms: number) => {
  const hours = ms / (1000 * 60 * 60)
  return hours > 1000
    ? `${(hours / 1000).toFixed(1)}K h`
    : `${hours.toFixed(1)} h`
}

export const toHoursMinutesSecondsFromSeconds = (
  seconds: number | undefined,
  includeHour: boolean = true
) => {
  if (seconds !== undefined) {
    const minutes = Math.floor((seconds % (60 * 60)) / 60)
    const hours = Math.floor(seconds / 3600)
    const secs = Math.ceil((seconds % (60 * 60)) % 60)
    return `${
      includeHour ? `${hours.toString().padStart(2, '0')}:` : ''
    }${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  }
}

export const toDurationTimeMs = (
  start: string,
  end: string | Date | null
): number => {
  return end ? new Date(end).getTime() - new Date(start).getTime() : 0
}

export const toDuration = (start: string, end: string | Date): string => {
  const driveDurationMs = toDurationTimeMs(start, end)
  return toHoursAndMinutes(driveDurationMs / HOUR_MS) || '—'
}
export const toDurationPrecise = (
  start: string,
  end: string | Date | null
): string => {
  const driveDurationMs = toDurationTimeMs(start, end)
  return toHoursMinutesSeconds(driveDurationMs) || '—'
}

export const formatTimestamp = (timestamp: string) =>
  new Date(timestamp).toLocaleString(undefined, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    second: '2-digit',
  })

export const formatTimestampToDate = (timestamp: string) => {
  const date = new Date(timestamp)
    .toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
  return `${date[2]}-${date[0]}-${date[1]}`
}

export const formatTimestampToTime = (timestamp: string | null) =>
  timestamp
    ? new Date(timestamp).toLocaleString(undefined, {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
        second: '2-digit',
      })
    : '-'

export const formatDateRange = (startTime: Date, endTime: Date) => {
  const timeFormat = 'd MMM y'
  if (isSameDay(startTime, endTime)) {
    return format(startTime, 'd MMM')
  } else if (isSameMonth(startTime, endTime)) {
    return `${format(startTime, 'd')} - ${format(endTime, 'd MMM')}`
  } else if (isSameYear(startTime, endTime)) {
    return `${format(startTime, 'd MMM')} - ${format(endTime, 'd MMM y')}`
  }
  return `${format(startTime, timeFormat)} - ${format(endTime, timeFormat)}`
}
