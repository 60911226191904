import { useParams, useNavigate, useLocation } from 'react-router-dom'
import React, {
  ReactElement,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import {
  getInstructorData,
  getLessonTopics,
  getUser,
  LessonTopics,
  updateUser,
  User,
  UserStats,
  getUserStats,
} from '@yaak/components/services/api/api'
import style from './style.less'
import { UserEditDialog } from './../UserOverview/UserDialogs'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'
import { toastType, ToastTypes } from '@yaak/components/src/Toast/Toast'
import Typography, {
  TypographyTypes,
  TypographySizes,
} from '@yaak/components/src/Typography/Typography'
import AvatarNamePlate from '@yaak/components/src/AvatarNamePlate/AvatarNamePlate'
import Button from '@yaak/components/src/Button'
import Icon from '@yaak/components/src/Icon'
import Divider from '@yaak/components/src/Divider'
import Tooltip from '@yaak/components/src/Tooltip'
import Tabs from '@yaak/components/src/Tabs'
import Badge from '@yaak/components/src/Badge'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import ActiveStudents from '@yaak/components/src/ActiveStudents'
import StudentsPerformance from '@yaak/components/src/StudentsPerformance'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { DEIcon, ENIcon } from '@yaak/components/assets'
import appStyle from '../../style.less'
import { useTabIndex } from '@yaak/components/customHooks/useTabIndex'
import SimulationOverview from '../SimulationOverview'
import { useAuth0 } from '@auth0/auth0-react'
import { isSimAdmin } from '../../utils/simAdmin'

interface UserPageProps {
  token: string
}

interface UserPageHeaderProps {
  user: User
  roleContext: string
  showToast: Dispatch<SetStateAction<toastType | null>>
  onOpenEditDialog: () => void
}

const roleOrder = ['ds-student', 'ds-instructor', 'ds-admin', 'yaak-admin']

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const getUserRole = (roles: string[]) => {
  const roleIndex = roles.reduce((pre, curr) => {
    const currentIndex = roleOrder.indexOf(curr)
    return currentIndex > pre ? currentIndex : pre
  }, -1)
  return roleOrder[roleIndex]
}

const getLocaleInfo = (locale: string) => {
  switch (locale) {
    case 'de':
      return { text: 'Deutsch', icon: <DEIcon /> }
    default:
      return { text: 'English', icon: <ENIcon /> }
  }
}

const tabsMapping: Record<number, string> = {
  0: 'onroad',
  1: 'simulation',
}

const UserPage: React.FunctionComponent<UserPageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User | null>(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [currentRole, setCurrentRole] = useState<string>()
  const [activeStudents, setActiveStudents] = useState<User[]>([])
  const [userStats, setUserStats] = useState<UserStats>()
  const [lessonTopics, setLessonTopics] = useState<LessonTopics>()

  const navigate = useNavigate()
  const { userId, tab } = useParams()
  const query = useQuery()

  const { user: auth0User } = useAuth0()
  const simAdmin = isSimAdmin(auth0User)

  const currentTab = useTabIndex(tabsMapping, tab)

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser({
        token,
        onAlert: setShowToast,
        id: userId,
      })
      if (user) {
        setUser(user)
        setCurrentRole(getUserRole(user.roles || []))
      } else {
        navigate('/')
      }
      setLoading(false)
    }

    token && fetchUser()
  }, [token, userId])

  useEffect(() => {
    const fetchData = async () => {
      const instructorData = await getInstructorData({
        token,
        partnerID: user?.partnerId,
        instructorID: user?.id,
        onAlert: setShowToast,
      })
      setActiveStudents(instructorData?.students)
    }
    !simAdmin && token && user && fetchData()
  }, [user, token, simAdmin])

  useEffect(() => {
    const fetchData = async () => {
      const [userStats, lessonTopics] = await Promise.all([
        user &&
          getUserStats({
            userId: user.id,
            token,
            onAlert: setShowToast,
          }),
        getLessonTopics({ token, onAlert: setShowToast }),
      ])
      userStats && setUserStats(userStats)
      setLessonTopics(lessonTopics)
    }

    !simAdmin && token && user && fetchData()
  }, [token, user, simAdmin])

  const onEditUser = async (user: any) => {
    const userWithID = {
      ...user,
      id: userId,
    }
    const updatedUser = await updateUser({
      token,
      user: userWithID,
      onAlert: setShowToast,
    })
    updatedUser && setUser(updatedUser)
    setOpenEdit(false)
  }

  return (
    <div className={appStyle.page}>
      {loading ? (
        <ProgressBar />
      ) : (
        <>
          {user && (
            <UserPageHeader
              user={user}
              onOpenEditDialog={() => setOpenEdit(true)}
              showToast={setShowToast}
              roleContext={query.get('context') || currentRole || ''}
            />
          )}
          <Divider />
          <div className={style.userContentBox}>
            <div className={style.overview}>
              {simAdmin ? (
                <SimulationOverview
                  token={token}
                  withHeader={false}
                  userId={user?.id}
                  withDriver={false}
                />
              ) : currentRole === 'ds-student' ? (
                <Tabs
                  currentTab={currentTab}
                  setCurrentTab={(tab) => {
                    navigate(`/users/${user?.id}/${tabsMapping[tab]}`)
                  }}
                  tabs={[
                    {
                      index: 0,
                      text: 'On-road drives',
                      children: (
                        <DrivesOverview
                          token={token}
                          filter={{ userId }}
                          withHeader={false}
                          withInstructor={false}
                          fixedColumns={3}
                        />
                      ),
                    },
                    {
                      index: 1,
                      text: 'Simulation drives',
                      children: (
                        <SimulationOverview
                          token={token}
                          withHeader={false}
                          userId={user?.id}
                          withDriver={false}
                        />
                      ),
                    },
                  ]}
                />
              ) : (
                <DrivesOverview
                  token={token}
                  filter={{ userId }}
                  withHeader={false}
                  withInstructor={false}
                  fixedColumns={3}
                />
              )}
            </div>
            {!simAdmin && activeStudents && currentRole !== 'ds-student' ? (
              <ActiveStudents activeStudents={activeStudents} />
            ) : (
              !simAdmin &&
              user &&
              userStats &&
              lessonTopics && (
                <StudentsPerformance
                  user={user}
                  userStats={userStats}
                  lessonTopics={lessonTopics}
                />
              )
            )}
          </div>
        </>
      )}
      <UserEditDialog
        token={token}
        user={user}
        isOpen={openEdit}
        role={currentRole || ''}
        onClose={() => setOpenEdit(false)}
        onCreate={onEditUser}
      />
    </div>
  )
}

const UserPageHeader: React.FunctionComponent<UserPageHeaderProps> = ({
  user,
  roleContext,
  showToast,
  onOpenEditDialog,
}): ReactElement => {
  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content)
    showToast({ text: `Copied`, type: ToastTypes.success })
  }

  return (
    <div className={style.header}>
      <Breadcrumbs
        first={getBreadcrumbBase(roleContext)}
        second={{ text: `${user.firstName} ${user.lastName}` }}
      />
      <div className={style.headerMain}>
        <div className={style.headerMainRow}>
          <Typography
            type={TypographyTypes.headline}
            size={TypographySizes.small}
          >
            <AvatarNamePlate
              gravatarURL={user.profileImageURL}
              name={`${user.firstName} ${user.lastName}`}
            />
          </Typography>
          <div className={style.headerActions}>
            <Tooltip text={'Copy user ID'} position={'left'}>
              <Icon
                name={'Copy'}
                onClick={() => copyToClipboard(user.id)}
                className={style.icon}
                size={IconSizes.medium}
              />
            </Tooltip>
            <Tooltip text={'Copy email'} position={'top'}>
              <Icon
                name={'Email'}
                size={IconSizes.medium}
                onClick={() => copyToClipboard(user.email!)}
                className={style.icon}
              />
            </Tooltip>
            {user.phoneNumber ? (
              <Tooltip text={'Copy mobile number'} position={'right'}>
                <Icon
                  name={'Phone'}
                  size={IconSizes.medium}
                  onClick={() => copyToClipboard(user.phoneNumber || '')}
                  className={style.icon}
                />
              </Tooltip>
            ) : undefined}
          </div>
        </div>
        <Button
          secondary
          onClick={onOpenEditDialog}
          text={'Edit'}
          icon={<Icon name={'Edit'} />}
        />
      </div>
      <div className={style.headerInfo}>
        {user.active ? (
          <Badge withDot type={BadgeType.green} label={'Active'} />
        ) : (
          <Badge withDot type={BadgeType.red} label={'Inactive'} />
        )}
        <Badge
          type={BadgeType.grey}
          label={user.partner?.name || ''}
          icon={<Icon name={'Partner'} />}
        />
        {user.simEnabled && (
          <Badge
            type={BadgeType.grey}
            label={'Sim-enabled'}
            icon={<Icon name={'VR'} />}
          />
        )}
        <Badge
          type={BadgeType.grey}
          svg
          label={getLocaleInfo(user.preferredLanguage || 'en').text}
          icon={getLocaleInfo(user.preferredLanguage || 'en').icon}
        />
        {!user.tosAccepted && (
          <Badge
            type={BadgeType.red}
            label={'Missing ToS'}
            icon={<Icon name={'Cancel'} />}
          />
        )}
      </div>
    </div>
  )
}

const getBreadcrumbBase = (role: string) => {
  switch (role) {
    case 'ds-admin':
      return { text: 'DS admin', url: '/dsadmins' }
    case 'ds-instructor':
      return { text: 'Instructor', url: '/instructors' }
    case 'ds-student':
      return { text: 'Student', url: '/students' }
    case 'yaak-admin':
      return { text: 'Settings: Team', url: '/settings/team' }
    default:
      return { text: '', url: '' }
  }
}

export default UserPage
