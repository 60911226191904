import { formatTimestampToDate } from '@yaak/admin/src/helpers/time'
import { Timestamp } from './protobufParse'

const addLeadingZeros = (time: number) => (time < 10 ? '0' : '') + time

export const formatTime = (time: string, offset: number, onlyTime = false) => {
  const date = new Date(time)
  const timeAdded = new Date(date.setSeconds(date.getSeconds() + offset))
  const timeZone = timeAdded
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' })
    .substring(4)
  return onlyTime
    ? `${addLeadingZeros(timeAdded.getHours())}:${addLeadingZeros(
        timeAdded.getMinutes()
      )}:${addLeadingZeros(
        timeAdded.getSeconds()
      )}.${timeAdded.getMilliseconds()} ${timeZone}`
    : `${formatTimestampToDate(time)}, ${addLeadingZeros(
        timeAdded.getHours()
      )}:${addLeadingZeros(timeAdded.getMinutes())}:${addLeadingZeros(
        timeAdded.getSeconds()
      )}.${timeAdded.getMilliseconds()} ${timeZone}`
}

export const getTimezone = (date: Date) =>
  date
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' })
    .substring(4)

export const toSec = ({ seconds, nanos }: Timestamp): number => {
  return seconds + nanos * 1e-9
}

export const fixTime = (t: Timestamp, allowNegative = false): Timestamp => {
  const durationNanos = t.nanos
  const secsFromNanos = Math.floor(durationNanos / 1e9)
  const newSecs = t.seconds + secsFromNanos
  const remainingDurationNanos = durationNanos % 1e9
  const newNanos = Math.abs(
    Math.sign(remainingDurationNanos) === -1
      ? 1e9 + remainingDurationNanos
      : remainingDurationNanos
  )
  const result = { seconds: newSecs, nanos: newNanos }
  if ((!allowNegative && result.seconds < 0) || result.nanos < 0) {
    throw new Error(`Cannot normalize invalid time ${result}`)
  }
  return result
}

export const subtractTime = (
  { seconds: sec1, nanos: nsec1 }: Timestamp,
  { seconds: sec2, nanos: nsec2 }: Timestamp
): Timestamp => {
  return fixTime({ seconds: sec1 - sec2, nanos: nsec1 - nsec2 }, true)
}
