import React, { memo, useCallback, useEffect, useState } from 'react'
import style from './style.less'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { OPERATORS_MAPPING } from './consts'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'

export interface OperatorSelectProps {
  tagKey: string
  setOperatorsRef: any
}

const OperatorSelect = ({ tagKey, setOperatorsRef }: OperatorSelectProps) => {
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)
  const [operatorValue, setOperatorValue] = useState('')

  useEffect(() => {
    tag && setOperatorValue(tag.value.split(' ')[1])
  }, [tag])

  const onChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (tag && tags) {
        const operator = event.target.value
        setOperatorValue(operator)
        const queryParamToEdit = tag.value.split(' ').filter((t) => t !== '')
        if (queryParamToEdit) {
          const [start, , value] = queryParamToEdit
          const newVal = operatorValue === 'x-y' ? value.split('-')[0] : value
          const newFullTag = `${start} ${operator} ${newVal || ''}`
          tag.value = newFullTag
          update(tags.filter((t) => (t.key === tagKey ? newFullTag : t)))
        }
      }
    },
    [tag, tags, operatorValue]
  )

  return (
    tag && (
      <Select
        sx={{
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        variant="standard"
        value={operatorValue}
        className={style.filterMenuOperatorsSelect}
        MenuProps={{
          ref: setOperatorsRef,
        }}
        IconComponent={(_props) => {
          const opened = _props.className.toString().includes('iconOpen')
          return (
            <Icon
              className={style.iconSelect}
              version={Version.v2}
              name={opened ? 'ArrowDropUp' : 'ArrowDropDown'}
            />
          )
        }}
        onChange={onChange}
      >
        {OPERATORS_MAPPING[tag.value.split(' ')[0]]?.map((rate, index) => (
          <MenuItem key={index} value={rate.replace('= x-y', 'x-y')}>
            <Typography
              type={TypographyTypes.body}
              size={TypographySizes.small}
              version={Version.v2}
            >
              {rate}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    )
  )
}

export default memo(OperatorSelect)
