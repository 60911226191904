import { TypedMcapRecords } from '@mcap/core/dist/esm/src/types'

const protobuf = require('protobufjs')
const descriptor = require('protobufjs/ext/descriptor/index.js')

interface Channel {
  protoSchema: any
  schemaName: string
  topic: string
}

export interface Timestamp {
  seconds: number
  nanos: number
}

export interface Location {
  altitude: number
  latitude: number
  longitude: number
  timestamp: Timestamp
}

enum NavSatStatus {
  STATUS_FIX = 0,
  STATUS_SBAS_FIX = 1,
  STATUS_GBAS_FIX = 2,
  STATUS_NO_FIX = -1,
}

enum NavService {
  SERVICE_NONE = 0,
  SERVICE_GPS = 1,
  SERVICE_GLOSNASS = 2,
  SERVICE_COMPASS = 4,
  SERVICE_GALILEO = 8,
}

enum NavSatFix {
  COVARIANCE_TYPE_UNKNOWN = 0,
  COVARIANCE_TYPE_APPROXIMATED = 1,
  COVARIANCE_TYPE_DIAGONAL_KNOWN = 2,
  COVARIANCE_TYPE_KNOWN = 3,
}

enum Gear {
  P = 0,
  R = 1,
  N = 2,
  D = 3,
  B = 4,
}

export enum TurnSignal {
  OFF = 0,
  LEFT = 1,
  RIGHT = 2,
}

export interface Gnss {
  altitude: number
  has_highprecision_loc: boolean
  heading: number
  heading_error: number
  hp_loc_altitude: number
  hp_loc_latitude: number
  hp_loc_longitude: number
  latitude: number
  longitude: number
  position_covariance: number[]
  position_covariance_type: NavSatFix
  service: NavService
  session_timestamp: number
  speed: number
  speed_error: number
  status: NavSatStatus
  time_stamp: Timestamp
}

export interface VehicleMotion {
  time_stamp: Timestamp
  session_timestamp: number
  acceleration_x: number
  acceleration_y: number
  acceleration_z: number
  speed: number
  wheel_speed_fr: number
  wheel_speed_fl: number
  wheel_speed_rr: number
  wheel_speed_rl: number
  steering_angle: number
  steering_angle_normalized: number
  gas_pedal_normalized: number
  brake_pedal_normalized: number
  instructor_pedals_used: boolean
  gear: Gear
  speed_dashboard: number
  mileage: number
}

export interface VehicleState {
  time_stamp: Timestamp
  session_timestamp: number
  door_closed_fl: boolean
  door_closed_fr: boolean
  door_closed_rl: boolean
  door_closed_rr: boolean
  driver_seatbelt_on: boolean
  passenger_seatbelt_on: boolean
  headlight_high_beam_on: boolean
  turn_signal: TurnSignal
}

export interface CurriculumPoint {
  location: Location
  timestamp: Timestamp
  type: number
}

export interface CurriculumLineString {
  end: Timestamp
  locations: Location[]
  start: Timestamp
  type: number
}

interface WeatherCondition {
  cloudcover: number
  condition: number
  description: string
  location: Location
  timestamp: Timestamp
  visibility: number
  weather_id: number
}

interface Turn {
  location: Location
  timestamp: Timestamp
  type: number
}

export interface Clip {
  end_timestamp: Timestamp
  start_timestamp: Timestamp
  locations: Location[]
}

export interface Prediction {
  action: number
  action_groundtruth_logprob: number
  action_l1_norm_prediction_and_groundtruth: number
  action_prediction: number
}

export interface SafetyScore {
  clip: Clip
  evaluation: number
  predictions: Prediction[]
  score: number
}

export interface Way {
  end: {
    seconds: number
  }
  highway: number
  length: number
  locations: Location[]
  maxspeed?: number
  start: Timestamp
  surface: number
  lanes?: number
}

interface DriveSessionInfo {
  drive_name: string
  time_stamp: Timestamp
  version: number
}

export type YAAKDataset =
  | 'intercom.driveSessionInfo'
  | 'intercom.Gnss'
  | 'intercom.ImageMetadata'
  | 'intercom.VehicleMotion'
  | 'intercom.VehicleState'
  | 'osm.Way'
  | 'osm.Turn'
  | 'osm.CurriculumPoint'
  | 'osm.CurriculumLineString'
  | 'osm.SafetyScore'
  | 'weather.Condition'

export const YAAK_SCHEMA_NAME_MAPPING: Record<string, YAAKDataset> = {
  driveSessionInfo: 'intercom.driveSessionInfo',
  gnss: 'intercom.Gnss',
  vehicleMotion: 'intercom.VehicleMotion',
  vehicleState: 'intercom.VehicleState',
  safetyScore: 'osm.SafetyScore',
  way: 'osm.Way',
  turn: 'osm.Turn',
  curriculumPoint: 'osm.CurriculumPoint',
  curriculumLineString: 'osm.CurriculumLineString',
  weatherCondition: 'weather.Condition',
}

export interface Metadata {
  'intercom.driveSessionInfo'?: DriveSessionInfo[]
  'intercom.Gnss'?: Gnss[]
  'intercom.ImageMetadata'?: any[]
  'intercom.VehicleMotion'?: VehicleMotion[]
  'intercom.VehicleState'?: VehicleState[]
  'osm.SafetyScore'?: SafetyScore[]
  'osm.Way'?: Way[]
  'osm.Turn'?: Turn[]
  'osm.CurriculumPoint'?: CurriculumPoint[]
  'osm.CurriculumLineString'?: CurriculumLineString[]
  'weather.Condition'?: WeatherCondition[]
}

const EXCLUDED_SCHEMAS = ['intercom.ImageMetadata']

export const calculateAcceleration = (vehicleMotion: VehicleMotion) =>
  Math.sqrt(
    Math.pow(vehicleMotion.acceleration_x || 0, 2) +
      Math.pow(vehicleMotion.acceleration_y || 0, 2) +
      Math.pow(vehicleMotion.acceleration_z || 0, 2)
  )

export const parseProtobufSchema = (
  schemaName: string,
  schemaData: Uint8Array
) => {
  const descriptorSet = descriptor.FileDescriptorSet.decode(schemaData)
  const root = protobuf.Root.fromDescriptor(descriptorSet)
  root.resolveAll()

  return root.lookupType(schemaName)
}

export const parseMessages = async (
  messageIterator: AsyncGenerator<TypedMcapRecords['Message'], void, void>,
  channelIdToProtoSchema: Map<number, Channel>
): Promise<Metadata> => {
  const result: any = {}

  for await (const message of messageIterator) {
    const info = channelIdToProtoSchema.get(message.channelId)

    const schemaName = info?.schemaName
    if (schemaName && !EXCLUDED_SCHEMAS.includes(schemaName)) {
      if (!result[schemaName]) {
        result[schemaName] = []
      }
      result[schemaName].push(info?.protoSchema.decode(message.data))
    }
  }

  return result
}
