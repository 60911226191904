import { create } from 'zustand'

export interface Tag {
  key: string
  group: string
  value: string
  savedQuery: boolean
  unit: string
}

interface TagsStoreState {
  tags: Tag[] | null
  update: (tags?: Tag[]) => void
}

export const useTagsStore = create<TagsStoreState>((set) => ({
  tags: null,
  update: (tags) =>
    set(() => ({
      tags: tags ? [...tags] : null,
    })),
}))
