import React from 'react'
import style from './style.less'
import Icon from '@yaak/components/src/Icon'
import classNames from 'classnames'
import { Version } from '@yaak/components/src/types'
import { capitalize } from '@yaak/nutron/src/utils'
import Typography, { TypographyTypes } from '../Typography'
import Badge, { BadgeType } from '../Badge/Badge'

export enum ToggleColors {
  green = 'green',
}

export const ToggleButtonTypes = {
  text: 'text',
  icon: 'icon',
}

interface IToggleButton {
  icon?: string
  text?: string
  value: string
  disabled?: boolean
  numberItem?: number
}

interface IToggleButtonProps {
  value: string
  onChange: (value: string) => void
  color: ToggleColors
  toggleButtons: IToggleButton[]
  type: string
}

const ToggleButton = (props: IToggleButtonProps) => {
  const { value: selectedValue, onChange, color, toggleButtons, type } = props

  const onSelect = (value: string) => () => {
    onChange(value)
  }

  const formatter = Intl.NumberFormat('en', { notation: 'compact' })

  return (
    <div
      className={classNames(
        style.toggleBtnContainer,
        type === ToggleButtonTypes.text
          ? style.toggleBtnTextContainer
          : undefined
      )}
    >
      {toggleButtons.map(({ icon, value, text, disabled, numberItem }) => (
        <div
          key={value}
          className={classNames(
            style.toggleBtn,
            text ? style.toggleBtnText : undefined,
            disabled ? style.toggleBtnDisabled : undefined,
            selectedValue === value && !text
              ? style[`toggleBtnSelected${capitalize(color)}`]
              : undefined,
            selectedValue === value
              ? text
                ? style.toggleBtnTextSelected
                : style.toggleBtnSelected
              : undefined
          )}
          onClick={!disabled ? onSelect(value) : undefined}
        >
          {icon && <Icon name={icon} version={Version.v2} />}
          {text && (
            <div className={style.toggleBtnTextBadge}>
              <Typography type={TypographyTypes.label} version={Version.v2}>
                {text}
              </Typography>
              {numberItem !== undefined && (
                <Badge
                  type={BadgeType.blue}
                  label={formatter.format(numberItem)}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default ToggleButton
