// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__header--sBglb {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.style__simulationDrivesWrapper--WajOG {\n  height: calc(100% - 9rem);\n}\n.style__simulationDrivesWrapperWithoutHeader--_o0sK {\n  height: 100%;\n  margin-top: 1rem;\n  width: 100%;\n}\n.style__iconRed--lJ0Bz {\n  color: #d72a1d;\n}\n.style__container--r0z5u {\n  overflow: hidden;\n  height: 100%;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SimulationOverview/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,mBAAA;EACA,8BAAA;AAHJ;AADA;EAQI,yBAAA;AAJJ;AAJA;EAYI,YAAA;EACA,gBAAA;EACA,WAAA;AALJ;AATA;EAkBI,cAAA;AANJ;AAZA;EAsBI,gBAAA;EACA,YAAA;EACA,aAAA;AAPJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  .simulationDrivesWrapper {\n    height: calc(100% - 9rem);\n  }\n\n  .simulationDrivesWrapperWithoutHeader {\n    height: 100%;\n    margin-top: @spacing-100;\n    width: 100%;\n  }\n\n  .iconRed {\n    color: @color-red-050;\n  }\n\n  .container {\n    overflow: hidden;\n    height: 100%;\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style__header--sBglb",
	"simulationDrivesWrapper": "style__simulationDrivesWrapper--WajOG",
	"simulationDrivesWrapperWithoutHeader": "style__simulationDrivesWrapperWithoutHeader--_o0sK",
	"iconRed": "style__iconRed--lJ0Bz",
	"container": "style__container--r0z5u"
};
export default ___CSS_LOADER_EXPORT___;
