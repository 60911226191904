import React from 'react'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyStyles,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import Badge from '@yaak/components/src/Badge'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import Tooltip from '@yaak/components/src/Tooltip'
import Icon from '@yaak/components/src/Icon'
import { Version } from '@yaak/components/src/types'
import Button from '@yaak/components/src/Button'
import { ICON_POSITIONS } from '@yaak/components/src/Button/Button'

interface ISelectionBannerProps {
  sessioncount?: number
  scenarioCount?: number
  onAdd?: () => void
  onRemove?: () => void
}

const SelectionActionBar = ({
  sessioncount = 0,
  scenarioCount = 0,
  onAdd,
  onRemove,
}: ISelectionBannerProps) => {
  return (
    <div className={style.container}>
      <div className={style.selectedContainer}>
        <Typography
          type={TypographyTypes.body}
          size={TypographySizes.small}
          styles={TypographyStyles.emphasis}
          version={Version.v2}
          color="color-green-038"
        >
          {sessioncount + scenarioCount} items selected
        </Typography>
        <div className={style.badges}>
          {sessioncount ? (
            <Tooltip text="Sessions" position="top">
              <Badge
                type={BadgeType.grey}
                label={sessioncount}
                icon={<Icon name="Session" version={Version.v2} />}
              />
            </Tooltip>
          ) : null}
          {scenarioCount ? (
            <Tooltip text="Scenarios" position="top">
              <Badge
                type={BadgeType.grey}
                label={scenarioCount}
                icon={<Icon name="Scenario" version={Version.v2} />}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
      {onAdd && (
        <div className={style.actionButton}>
          <Button
            text="Add to collection"
            icon={<Icon name="Add" version={Version.v2} />}
            iconPosition={ICON_POSITIONS.LEFT}
            tertiary
            onClick={onAdd}
          />
        </div>
      )}
      {onRemove && (
        <div className={style.actionButton}>
          <Button
            text="Remove from collection"
            icon={<Icon name="Delete" version={Version.v2} />}
            iconPosition={ICON_POSITIONS.LEFT}
            tertiary
            onClick={onRemove}
          />
        </div>
      )}
    </div>
  )
}
export default SelectionActionBar
