import React, { useEffect, useState, useRef, useContext, memo } from 'react'
import { useMetadataStore } from '../../stores/MetadataStore'
import {
  Metadata,
  YAAK_SCHEMA_NAME_MAPPING,
  YAAKDataset,
} from '../../utils/protobufParse'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import { useVideosStore } from '../../stores/VideosStore'
import { getSessionInfo } from '@yaak/components/services/api/api'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { getOffset } from '@yaak/nutron/src/utils/player'

const filterSafetyScoreData = (data: any, start: number, end: number) =>
  data.filter(
    (d: any) =>
      d.clip.start_timestamp?.seconds >= start &&
      d.clip.end_timestamp.seconds <= end
  )

const filterData = (data: Metadata, start: number, end: number) => {
  return Object.keys(data).reduce((acc, key) => {
    if (key === YAAK_SCHEMA_NAME_MAPPING.safetyScore) {
      acc[key] = filterSafetyScoreData(data[key], start, end)
    } else if (key === YAAK_SCHEMA_NAME_MAPPING.way) {
      acc[key] = data[key] as any
    } else {
      acc[key as keyof Metadata] = data[key as YAAKDataset]?.filter(
        (d: any) => {
          return d.time_stamp?.seconds >= start && d.time_stamp?.seconds <= end
        }
      )
    }
    return acc
  }, {} as Metadata)
}

const merge = (...objs: any) =>
  [...objs].reduce(
    (acc, obj) =>
      Object.keys(obj).reduce((a, k) => {
        acc[k] = Object.hasOwn(acc, k)
          ? [].concat(acc[k]).concat(obj[k])
          : obj[k]
        return acc
      }, {}),
    {}
  )

interface MetadataLoaderProps {
  sessionId?: string
  token?: string
}

const MetadataLoader: React.FunctionComponent<MetadataLoaderProps> = ({
  sessionId,
  token,
}) => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [metadata, setMetadata] = useState<Metadata>({})
  const {
    url,
    updateUrl,
    updateMetadata,
    updateSeconds,
    updateMetadataLoadingFinished,
  } = useMetadataStore(
    useShallow((state) => ({
      url: state.url,
      updateUrl: state.updateUrl,
      updateMetadata: state.updateMetadata,
      updateSeconds: state.updateSeconds,
      updateMetadataLoadingFinished: state.updateMetadataLoadingFinished,
    }))
  )
  const [finished, setFinished] = useState<boolean>(false)

  const { begin, context, end } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      end: state.end,
      context: state.context,
    }))
  )
  const { session } = useVideosStore(
    useShallow((state) => ({
      session: state.session,
    }))
  )
  const workerRef = useRef<Worker>()
  const [worker, setWorker] = useState<Worker | null>(null)

  useEffect(() => {
    const getUrl = async () => {
      if (sessionId && token) {
        const sessionInfo = await getSessionInfo({
          token,
          sessionId,
          onAlert: setShowToast,
        })

        const url = sessionInfo?.metadataMCAP

        if (url) {
          updateUrl(url)
        } else {
          setShowToast({
            text: `No metadata url available`,
            type: ToastTypes.error,
          })
        }
      }
    }
    getUrl()
  }, [token, sessionId])

  useEffect(() => {
    if (url && !workerRef.current && sessionId) {
      updateMetadataLoadingFinished(sessionId, false)
      const newWorker: Worker = new Worker(
        new URL('./../../utils/worker', import.meta.url)
      )
      setWorker(newWorker)
      workerRef.current = newWorker
    }

    return () => {
      const worker = workerRef.current
      if (worker) {
        worker.terminate()
      }
      workerRef.current = undefined
    }
  }, [url, workerRef, sessionId])

  useEffect(() => {
    if (worker) {
      worker.postMessage({
        url,
        begin:
          begin !== undefined &&
          new Date(session.startTimestamp).getTime() / 1000 +
            getOffset(begin, context),
        end:
          end !== undefined &&
          new Date(session.endTimestamp).getTime() / 1000 + end + context,
      })

      worker.onmessage = function (event) {
        if (event.data.log) {
          setMetadata((prevMetadata) => {
            if (
              begin &&
              session.offsetURLStartTimestamp &&
              session.offsetURLEndTimestamp
            ) {
              const start = Math.floor(
                new Date(session.offsetURLStartTimestamp).getTime() / 1000
              )
              const end = Math.floor(
                new Date(session.offsetURLEndTimestamp).getTime() / 1000
              )

              return merge(prevMetadata, filterData(event.data.log, start, end))
            } else {
              return merge(prevMetadata, event.data.log)
            }
          })
        }

        if (event.data.finished) {
          setFinished(true)
        }
      }
    }
  }, [begin, session, end, context, worker])

  useEffect(() => {
    if (finished && sessionId) {
      updateMetadataLoadingFinished(sessionId, true)
      const worker = workerRef.current
      if (worker) {
        worker.terminate()
      }
      workerRef.current = undefined
    }
  }, [finished, workerRef, sessionId])

  useEffect(() => {
    if (metadata && sessionId) {
      const gnss = metadata[YAAK_SCHEMA_NAME_MAPPING.gnss]
      if (gnss) {
        updateMetadata(sessionId, metadata)
        const seconds = gnss.map((g) => g.time_stamp.seconds)
        updateSeconds(seconds)
      }
    }
  }, [sessionId, metadata])

  return <></>
}

export default memo(MetadataLoader)
