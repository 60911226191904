import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react'
import style from './style.less'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import { Popover, PopoverVirtualElement } from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'
import MenuItem from '@mui/material/MenuItem'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import {
  SEARCH_TAGS_MAPPING,
  SEARCH_TAGS_REVERSE_MAPPING,
  SECOND_LEVEL_FILTER_ITEMS,
  THIRD_LEVEL_FILTER_ITEMS,
  TOP_LEVEL_FILTER_ITEMS,
  TOP_LEVEL_FILTER_ITEMS_ICONS,
} from './consts'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import FilterSearch from './FilterSearch'
import classNames from 'classnames'

const createMenuItemKey = (name: string) =>
  name.toLowerCase().replace(/\s/g, '_')

interface FilterMenuItemProps {
  mapping?: Record<string, string[]>
  text: string
  onClick?: (value: string) => void
  selected: boolean
}

const FilterMenuItem = ({
  text,
  mapping,
  onClick,
  selected,
}: FilterMenuItemProps) => {
  return (
    <div className={style.filterMenuItem} onClick={() => onClick?.(text)}>
      <Typography
        className={style.filterMenuItemText}
        version={Version.v2}
        type={TypographyTypes.label}
      >
        {text}
      </Typography>
      <div className={style.filterMenuItemRight}>
        {selected && <span className={style.filterMenuItemDot}></span>}
        <Typography
          className={style.filterMenuItemText}
          version={Version.v2}
          type={TypographyTypes.label}
        >
          {mapping?.[text]?.length}
        </Typography>
      </div>
    </div>
  )
}

interface ISubmenuProps {
  topLevel: string
  subMenuItems: string[]
  open: boolean
  isSelected: (text: string) => boolean
  onClose: () => void
  onClick: (tag: string | null) => any
}

const SubMenu = ({
  topLevel,
  subMenuItems,
  open,
  isSelected,
  onClose,
  onClick,
}: ISubmenuProps) => (
  <NestedMenuItem
    leftIcon={
      TOP_LEVEL_FILTER_ITEMS_ICONS[topLevel] ? (
        <Icon
          name={TOP_LEVEL_FILTER_ITEMS_ICONS[topLevel]}
          version={Version.v2}
        />
      ) : null
    }
    key={createMenuItemKey(topLevel)}
    label={
      (
        <FilterMenuItem
          text={topLevel}
          mapping={SECOND_LEVEL_FILTER_ITEMS}
          selected={isSelected(topLevel)}
        />
      ) as any
    }
    className={classNames(
      !TOP_LEVEL_FILTER_ITEMS_ICONS[topLevel] ? style.subMenu : undefined
    )}
    parentMenuOpen={open}
  >
    {subMenuItems.map((secondLevel) =>
      Object.keys(THIRD_LEVEL_FILTER_ITEMS).includes(secondLevel) ? (
        <SubMenu
          key={createMenuItemKey(secondLevel)}
          topLevel={secondLevel}
          subMenuItems={THIRD_LEVEL_FILTER_ITEMS[secondLevel]}
          open={open}
          isSelected={isSelected}
          onClick={onClick}
          onClose={onClose}
        />
      ) : (
        <MenuItem onClick={onClose} key={createMenuItemKey(secondLevel)}>
          <FilterMenuItem
            text={secondLevel}
            mapping={SECOND_LEVEL_FILTER_ITEMS}
            onClick={(value) => {
              onClick(
                SEARCH_TAGS_MAPPING[value] ? SEARCH_TAGS_MAPPING[value] : null
              )
            }}
            selected={isSelected(secondLevel)}
          />
        </MenuItem>
      )
    )}
  </NestedMenuItem>
)

interface FilterMenuProps {
  onClick: (tag: string | null) => any
  onClearFilters: () => void
  showClearAction: boolean
}

const FilterMenu = ({
  showClearAction,
  onClick,
  onClearFilters,
}: FilterMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined
  >(null)
  const open = Boolean(anchorEl)
  const [search, setSearch] = useState<string>('')
  const { tags } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
    }))
  )

  const onIconClick = (e: MouseEvent) => setAnchorEl(e.currentTarget as Element)

  const onClose = () => {
    setAnchorEl(null)
    setSearch('')
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '')
  }

  const isSelected = useCallback(
    (text: string) =>
      !!tags?.find((tag) =>
        tag.group.toLowerCase().includes(text.toLowerCase())
      ) ||
      !!tags?.find(
        (tag) =>
          SEARCH_TAGS_REVERSE_MAPPING[tag.value.split(' ')[0]] === text ||
          (THIRD_LEVEL_FILTER_ITEMS[text] &&
            THIRD_LEVEL_FILTER_ITEMS[text].includes(
              SEARCH_TAGS_REVERSE_MAPPING[tag.value.split(' ')[0]]
            ))
      ),
    [tags]
  )

  const filteredItems: Record<string, string[]> = useMemo(
    () =>
      Object.entries({
        ...SECOND_LEVEL_FILTER_ITEMS,
        ...THIRD_LEVEL_FILTER_ITEMS,
      }).reduce((acc, [category, items]) => {
        const filtered = items.filter((item) =>
          item.toLowerCase().includes(search.toLowerCase())
        )
        if (search && filtered.length > 0) {
          return { ...acc, [category]: filtered }
        }
        return acc
      }, {}),
    [search]
  )

  return (
    <div>
      <button
        className={classNames(
          style.filterButton,
          open ? style.filterButtonOpen : undefined
        )}
      >
        <Icon name={'Filter'} version={Version.v2} onClick={onIconClick} />
      </button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ '& .MuiPopover-paper': { padding: '8px 0' } }}
        className={style.filterMenu}
      >
        <FilterSearch value={search} onChange={handleSearchChange} />
        {!search &&
          TOP_LEVEL_FILTER_ITEMS.map((topLevel) => (
            <SubMenu
              key={createMenuItemKey(topLevel)}
              topLevel={topLevel}
              subMenuItems={SECOND_LEVEL_FILTER_ITEMS[topLevel]}
              open={open}
              isSelected={isSelected}
              onClick={onClick}
              onClose={onClose}
            />
          ))}
        {search && (
          <div className={style.filteredContainer}>
            {Object.entries(filteredItems).map(([category, items]) => (
              <>
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.small}
                  className={style.category}
                >
                  {category}
                </Typography>
                {items.map((item) => (
                  <MenuItem key={createMenuItemKey(item)} onClick={onClose}>
                    <FilterMenuItem
                      text={item}
                      mapping={SECOND_LEVEL_FILTER_ITEMS}
                      onClick={(value) => {
                        onClick(
                          SEARCH_TAGS_MAPPING[value]
                            ? SEARCH_TAGS_MAPPING[value]
                            : null
                        )
                      }}
                      selected={isSelected(item)}
                    />
                  </MenuItem>
                ))}
              </>
            ))}
          </div>
        )}
        {showClearAction && (
          <div className={style.clearButtonContainer}>
            <button className={style.clearButton} onClick={onClearFilters}>
              <Typography type={TypographyTypes.label}>
                Clear filters
              </Typography>
            </button>
          </div>
        )}
      </Popover>
    </div>
  )
}

export default memo(FilterMenu)
