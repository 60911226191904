import React, {
  memo,
  MutableRefObject,
  useCallback,
  useContext,
  useState,
} from 'react'
import style from './style.less'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Icon from '../Icon/Icon'
import FilterMenu from '../FilterMenu/FilterMenu'
import Button from '../Button'
import Autocomplete from '@mui/material/Autocomplete'
import { SavedQuery } from './SearchQueryBar'
import { useSearchParams } from 'react-router-dom'
import { useTagsStore } from '../stores/Tags'
import { getNLSSearch } from '../../services/api/api'
import { ToastContext, ToastContextType } from '../../context/toastContext'
import {
  GROUPS_MAPPING,
  OPERATORS_KEYS,
  queryToTags,
  UNIT_MAPPING,
  UNIT_MAPPING_KEYS,
} from './utils'
import { useShallow } from 'zustand/react/shallow'
import { Version } from '../types'
import { ToastTypes } from '../Toast/Toast'

const uuid = require('uuid')

interface NLSAutocompleteProps {
  token: string
  textFieldRef: MutableRefObject<HTMLInputElement | null>
  onClearFilters: () => void
  savedQueries: SavedQuery[]
  onFilterMenuClick: (value: string | null) => void
  onSaveButtonClick: () => void
  onSavedQueryClick: (savedQuery: SavedQuery) => void
}

const NLSAutocomplete: React.FunctionComponent<NLSAutocompleteProps> = ({
  token,
  textFieldRef,
  onClearFilters,
  savedQueries,
  onFilterMenuClick,
  onSaveButtonClick,
  onSavedQueryClick,
}) => {
  const [searchParams] = useSearchParams()
  const [inputValue, setInputValue] = useState('')
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [query, setQuery] = useState<string | null>(null)
  const context = searchParams.get('context')
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )

  const onKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && query) {
        const result = await getNLSSearch({
          query,
          token,
          onAlert: setShowToast,
        })
        const queryTags = queryToTags(result.query)
          .concat(queryToTags(result.filters))
          .join('&')

        if (!queryTags) {
          setShowToast({
            text: 'Natural language optimization required to handle this search.',
            type: ToastTypes.info,
          })
        }

        const splitParams = queryTags ? queryTags.split('&') : []
        const createdTags = splitParams.map((value) => {
          const type = decodeURIComponent(value).split(' ')[0].replace(/ /g, '')
          return {
            group: GROUPS_MAPPING[type as OPERATORS_KEYS] || '',
            value: decodeURIComponent(value),
            key: `${uuid.v4()}_${decodeURIComponent(value)}`,
            savedQuery: false,
            unit: UNIT_MAPPING[type as UNIT_MAPPING_KEYS] || '',
          }
        })
        createdTags.length > 0 && update(createdTags)
      }
    },
    [token, query, context]
  )

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  return (
    <Autocomplete
      id="search"
      multiple
      sx={{ width: '100%' }}
      componentsProps={{
        paper: {
          sx: {
            maxWidth: '100%',
          },
        },
      }}
      freeSolo
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (event && event.type === 'blur') {
          setInputValue('')
        } else if (reason !== 'reset') {
          setInputValue(value)
        }
      }}
      getOptionKey={(option) => (option as SavedQuery).value}
      className={style.autocomplete}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={(e) => e.stopPropagation()}
          ref={textFieldRef}
          placeholder={'Search dataset'}
          className={style.searchInput}
          inputProps={{ ...params.inputProps, autoComplete: 'off' }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Icon
                    name={'Search'}
                    styles={{ fontSize: '1.25rem', color: '#7d8287' }}
                  />
                </InputAdornment>
              </>
            ),
            endAdornment: (
              <>
                {inputValue && (
                  <Icon
                    name={'Close'}
                    version={Version.v2}
                    className={style.deleteIcon}
                    onClick={() => setInputValue('')}
                  />
                )}
                <FilterMenu
                  onClick={onFilterMenuClick}
                  onClearFilters={onClearFilters}
                  showClearAction={!!searchParams.get('q')}
                />
                {tags && tags?.length > 0 && (
                  <Button
                    className={style.saveQuery}
                    onClick={onSaveButtonClick}
                    text={'Save'}
                    tertiary
                  />
                )}
              </>
            ),
          }}
        />
      )}
      disabled={false}
      options={savedQueries}
      getOptionLabel={(option: any) => option.value}
      renderOption={(props, savedQuery) => {
        return (
          <li
            {...props}
            className={style.savedQueryOption}
            key={`${uuid.v4()}_${savedQuery.value}`}
            onClick={() => onSavedQueryClick(savedQuery)}
          >
            {savedQuery.value}
            <div className={style.optionActions}>
              <Icon
                name={'Copy'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  savedQuery.onCopy?.(savedQuery.value)
                }}
              />
              <Icon
                name={'Edit'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  savedQuery.onEdit?.(savedQuery)
                }}
              />
              <Icon
                name={'Delete'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  savedQuery.onDelete?.(savedQuery)
                }}
              />
            </div>
          </li>
        )
      }}
      forcePopupIcon={false}
      isOptionEqualToValue={() => false}
    />
  )
}

export default memo(NLSAutocomplete)
