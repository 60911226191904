import { create } from 'zustand'
import { Metadata } from '../utils/protobufParse'
import { ChartXY } from '@lightningchart/lcjs'

export type Setting = {
  color: string
  display: boolean
}

export type MetadataKeys =
  | 'steering_angle_normalized'
  | 'gas_pedal_normalized'
  | 'brake_pedal_normalized'
  | 'gear'
  | 'speed'
  | 'turn_signal'
  | 'hp_loc_latitude'
  | 'hp_loc_longitude'
  | 'hp_loc_altitude'
  | 'heading'
  | 'position_covariance'
  | 'heading_error'
  | 'steering_angle'
  | 'acceleration'

export type ChartKeys =
  | 'steering_angle_normalized'
  | 'gas_pedal_normalized'
  | 'brake_pedal_normalized'
  | 'speed'
  | 'steering_angle'
  | 'acceleration'

export type MetadataSettings = Record<MetadataKeys, Setting>
export type ChartSettings = Record<ChartKeys, Setting>

const DEFAULT_METADATA_SETTINGS: MetadataSettings = {
  steering_angle_normalized: {
    color: LESS_COLORS['new-color-purple-039'],
    display: true,
  },
  gas_pedal_normalized: {
    color: LESS_COLORS['color-lime-062'],
    display: true,
  },
  brake_pedal_normalized: {
    color: LESS_COLORS['color-lime-036'],
    display: true,
  },
  gear: {
    color: LESS_COLORS['color-pink-054'],
    display: true,
  },
  speed: {
    color: LESS_COLORS['new-color-yellow-059'],
    display: true,
  },
  turn_signal: {
    color: LESS_COLORS['color-green-038'],
    display: true,
  },
  hp_loc_latitude: {
    color: LESS_COLORS['new-color-blue-070'],
    display: true,
  },
  hp_loc_longitude: {
    color: LESS_COLORS['new-color-blue-036'],
    display: true,
  },
  hp_loc_altitude: {
    color: LESS_COLORS['new-color-blue-090'],
    display: true,
  },
  heading: {
    color: LESS_COLORS['new-color-orange-063'],
    display: true,
  },
  position_covariance: {
    color: LESS_COLORS['new-color-red-057'],
    display: true,
  },
  heading_error: {
    color: LESS_COLORS['new-color-orange-037'],
    display: true,
  },
  steering_angle: {
    color: LESS_COLORS['new-color-purple-069'],
    display: true,
  },
  acceleration: {
    color: LESS_COLORS['color-pink-074'],
    display: true,
  },
}

const DEFAULT_CHART_SETTINGS: ChartSettings = {
  steering_angle_normalized: {
    color: '#ff82b7',
    display: true,
  },
  gas_pedal_normalized: {
    color: '#ff9c57',
    display: true,
  },
  brake_pedal_normalized: {
    color: '#6faeff',
    display: true,
  },
  speed: {
    color: '#d66700',
    display: true,
  },
  steering_angle: {
    color: '#00315d',
    display: true,
  },
  acceleration: {
    color: '#884fd8',
    display: true,
  },
}

export type MapSettingsKeys = 'route_tags' | 'annotations'

export type MapSettings = Record<MapSettingsKeys, Setting>

const DEFAULT_MAP_SETTINGS: MapSettings = {
  route_tags: {
    color: LESS_COLORS['color-neutral-cool-060'],
    display: true,
  },
  annotations: {
    color: LESS_COLORS['new-color-red-066'],
    display: true,
  },
}

interface MetadataStoreProps {
  chartSettings: ChartSettings
  metadataSettings: MetadataSettings
  mapSettings: MapSettings
  chart: ChartXY | null
  url: string
  metadata: Record<string, Metadata>
  seconds: number[]
  loadingFinished: any
}

interface MetadataStoreState {
  chartSettings: ChartSettings
  metadataSettings: MetadataSettings
  mapSettings: MapSettings
  chart: ChartXY | null
  url: string
  seconds: number[]
  metadata: Record<string, Metadata>
  loadingFinished: any
  updateUrl: (url: string) => void
  updateChart: (chart: ChartXY) => void
  updateMetadata: (sessionId: string, metadata: Metadata) => void
  updateSeconds: (seconds: number[]) => void
  updateMetadataSettings: (settings: MetadataSettings) => void
  updateChartSettings: (settings: ChartSettings) => void
  updateMapSettings: (mapSettings: MapSettings) => void
  updateMetadataLoadingFinished: (id: string, loadingFinished: boolean) => void
  reset: () => void
}

const initialState: MetadataStoreProps = {
  chartSettings: DEFAULT_CHART_SETTINGS,
  metadataSettings: DEFAULT_METADATA_SETTINGS,
  mapSettings: DEFAULT_MAP_SETTINGS,
  loadingFinished: {},
  chart: null,
  url: '',
  metadata: {},
  seconds: [],
}

export const useMetadataStore = create<MetadataStoreState>((set) => ({
  chartSettings: DEFAULT_CHART_SETTINGS,
  metadataSettings: DEFAULT_METADATA_SETTINGS,
  mapSettings: DEFAULT_MAP_SETTINGS,
  chart: null,
  url: '',
  loadingFinished: {},
  metadata: {} as Record<string, Metadata>,
  seconds: [],
  updateUrl: (url) =>
    set(() => ({
      url,
    })),
  updateMetadata: (sessionId, metadata) => {
    return set((state) => ({
      ...state,
      metadata: {
        ...state.metadata,
        [sessionId]: {
          ...metadata,
        },
      },
    }))
  },
  updateSeconds: (seconds) =>
    set(() => ({
      seconds,
    })),
  updateChart: (chart: ChartXY) =>
    set(() => ({
      chart,
    })),
  updateMetadataSettings: (metadataSettings) =>
    set(() => ({
      metadataSettings,
    })),
  updateChartSettings: (chartSettings) =>
    set(() => ({
      chartSettings,
    })),
  updateMapSettings: (mapSettings) =>
    set(() => ({
      mapSettings,
    })),
  updateMetadataLoadingFinished: (id, loadingFinished) =>
    set((state) => ({
      loadingFinished: {
        ...state.loadingFinished,
        [id]: loadingFinished,
      },
    })),
  reset: () => {
    set(initialState)
  },
}))
