import {
  FILTER_VALUES_MATCHING_INPUT_KEYS,
  FILTER_VALUES_MATCHING_MULTI_KEYS,
  FilterValuesMatchingInputProps,
} from './consts'

export const findMatchingValues = (
  obj:
    | Record<FILTER_VALUES_MATCHING_MULTI_KEYS, string[]>
    | Record<FILTER_VALUES_MATCHING_INPUT_KEYS, FilterValuesMatchingInputProps>,
  value: string
) => Object.keys(obj).filter((key) => value.includes(key))[0]
