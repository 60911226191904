// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__partnerPageTableWrapper--RisK3 {\n  height: calc(100% - 12rem);\n}\n.style__searchBox--FK8iG {\n  flex-grow: 1;\n}\n.style__actionBarButtonBox--OnZ5X {\n  display: flex;\n  justify-content: flex-end;\n  white-space: nowrap;\n}\n.style__gridColFixedRight4--QoBek {\n  max-width: 1rem;\n  position: sticky;\n  right: 0;\n  z-index: 1;\n}\n.style__gridColFixedRight3--kfZgn {\n  max-width: 1rem;\n  position: sticky;\n  right: 64px;\n  z-index: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PartnerOverview/style.less"],"names":[],"mappings":"AAEA;EAEI,0BAAA;AAFJ;AAAA;EAMI,YAAA;AAHJ;AAHA;EAUI,aAAA;EACA,yBAAA;EACA,mBAAA;AAJJ;AARA;EAgBI,eAAA;EACA,gBAAA;EACA,QAAA;EAEA,UAAA;AANJ;AAdA;EAwBI,eAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;AAPJ","sourcesContent":["@import '@yaak/components/src/spacing';\n\n:local {\n  .partnerPageTableWrapper {\n    height: calc(100% - 12rem);\n  }\n\n  .searchBox {\n    flex-grow: 1;\n  }\n\n  .actionBarButtonBox {\n    display: flex;\n    justify-content: flex-end;\n    white-space: nowrap;\n  }\n\n  .gridColFixedRight4 {\n    max-width: 1rem;\n    position: sticky;\n    right: 0;\n\n    z-index: 1;\n  }\n\n  .gridColFixedRight3 {\n    max-width: 1rem;\n    position: sticky;\n    right: 64px;\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partnerPageTableWrapper": "style__partnerPageTableWrapper--RisK3",
	"searchBox": "style__searchBox--FK8iG",
	"actionBarButtonBox": "style__actionBarButtonBox--OnZ5X",
	"gridColFixedRight4": "style__gridColFixedRight4--QoBek",
	"gridColFixedRight3": "style__gridColFixedRight3--kfZgn"
};
export default ___CSS_LOADER_EXPORT___;
