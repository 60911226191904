import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  memo,
  useMemo,
} from 'react'
import ReactPlayer from 'react-player'
import style from './style.less'
import Icon, { IconSizes } from '../Icon'
import { Version } from '../types'
import classNames from 'classnames'
import { OnProgressProps } from 'react-player/base'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import { toHoursMinutesSecondsFromSeconds } from '@yaak/admin/src/helpers/time'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { SearchScenario } from '@yaak/components/services/api/api'
import { useSearchParams } from 'react-router-dom'
import ProgressBar from '../ProgressBar'
import { getOffset } from '@yaak/nutron/src/utils/player'

interface VideoPlayerStoreState {
  currentPlayingId: string
  updatePlayingId: (id: string) => void
}

export const useGridViewPlayerStore = create<VideoPlayerStoreState>((set) => ({
  currentPlayingId: '',
  updatePlayingId: (id) => set(() => ({ currentPlayingId: id })),
}))

const MAX_BUFFER_LENGTH_SECONDS = 60
const MAX_BUFFER_SIZE_BYTES = 20 * 1024 * 1024

interface IGridViewPlayerProps {
  token: string
  id: string
  url: string
  startOffset?: number
  endOffset?: number
  scenarios?: SearchScenario[]
}

const GridViewPlayer = ({
  token,
  id,
  url,
  startOffset,
  endOffset,
  scenarios,
}: IGridViewPlayerProps) => {
  const [searchParams] = useSearchParams()
  const context = parseInt(searchParams.get('context') || '0')
  const player = useRef<ReactPlayer>(null)
  const [buffering, setBuffering] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [isEndReached, setIsEndReached] = useState(false)
  const [playedSecs, setPlayedSecs] = useState(0)
  const [duration, setDuration] = useState(0)

  const lineRef = useRef<HTMLDivElement>(null)

  const startOffsetWithContext = useMemo(() => {
    return startOffset === undefined ? 0 : getOffset(startOffset, context)
  }, [startOffset, context])

  const endOffsetWithContext = useMemo(() => {
    return endOffset === undefined
      ? 0
      : endOffset + context > duration
      ? duration
      : endOffset + context
  }, [endOffset, context, duration])

  const maxDuration =
    startOffset !== undefined && endOffset !== undefined
      ? endOffsetWithContext - startOffsetWithContext
      : duration

  const { currentPlayingId, updatePlayingId } = useGridViewPlayerStore(
    useShallow((state) => ({
      currentPlayingId: state.currentPlayingId,
      updatePlayingId: state.updatePlayingId,
    }))
  )

  useEffect(() => {
    if (currentPlayingId !== id) {
      setPlaying(false)
    }
  }, [currentPlayingId])

  useEffect(() => {
    setPlayedSecs(0)
  }, [context])

  useEffect(() => {
    const maxDuration = endOffsetWithContext || duration
    if (playedSecs > maxDuration) {
      setPlaying(false)
      player.current?.seekTo(startOffsetWithContext, 'seconds')
    }
  }, [playedSecs, duration, endOffsetWithContext, startOffsetWithContext])

  useEffect(() => {
    setPlaying(false)
    setPlayedSecs(startOffsetWithContext)
    player.current?.seekTo(startOffsetWithContext, 'seconds')
  }, [url, startOffsetWithContext])

  const scenarioGradient = useMemo(() => {
    const highlighted =
      startOffset !== undefined && endOffset !== undefined
        ? [
            {
              startOffset: context,
              endOffset: context + (endOffset - startOffset),
            },
          ]
        : scenarios
    if (highlighted && highlighted?.length > 0 && maxDuration) {
      let gradient = 'linear-gradient(to right,'
      let prev = 0
      highlighted.forEach(({ startOffset, endOffset }, i) => {
        if (prev !== startOffset)
          gradient = `${gradient} transparent ${(prev * 100) / maxDuration}% ${
            (startOffset * 100) / maxDuration
          }%,`
        gradient = `${gradient} #6680FF ${(startOffset * 100) / maxDuration}% ${
          (endOffset * 100) / maxDuration
        }%,`
        prev = endOffset
        if (i === highlighted.length - 1) {
          gradient = `${gradient} transparent ${
            (endOffset * 100) / maxDuration
          }%)`
        }
      })
      return gradient
    }
    return ''
  }, [scenarios, maxDuration, startOffset, endOffset])

  const handleProgress = useCallback(
    (progress: OnProgressProps) => {
      playing && setPlayedSecs(progress.playedSeconds)
    },
    [playing]
  )

  const togglePlayer = () => {
    if (!playing) updatePlayingId(id)
    setIsEndReached(false)
    setPlaying(!playing)
  }

  const onSkipForward = () => {
    const nextOffset = scenarios?.find(
      ({ startOffset }) => startOffset > playedSecs
    )
    if (nextOffset) {
      player.current?.seekTo(nextOffset.startOffset, 'seconds')
      setPlayedSecs(nextOffset.startOffset)
    }
  }

  const onSkipBackward = () => {
    const lastOffset = scenarios?.findLast(
      ({ endOffset }) => playedSecs > endOffset
    )
    if (lastOffset) {
      player.current?.seekTo(lastOffset.startOffset, 'seconds')
      setPlayedSecs(lastOffset.startOffset)
    }
  }

  const playedSecsWithOffset = startOffset
    ? playedSecs - startOffsetWithContext
    : playedSecs
  const showSkipButton =
    playedSecsWithOffset > 0 && scenarios && scenarios?.length > 0

  const onLineClick = useCallback(
    (event: React.MouseEvent) => {
      if (lineRef.current) {
        const bounds = lineRef.current.getBoundingClientRect()
        const position = event.pageX - bounds.x
        const width = lineRef.current.offsetWidth
        const seek = (position / width) * maxDuration
        const seekWithOffset = startOffset
          ? startOffsetWithContext + seek
          : seek
        player.current?.seekTo(seekWithOffset, 'seconds')
        setPlayedSecs(seekWithOffset)
        setIsEndReached(false)
      }
    },
    [maxDuration, startOffset]
  )
  const shouldReplay =
    duration &&
    !playing &&
    (isEndReached || playedSecsWithOffset >= maxDuration)

  return (
    <div className={style.player}>
      <div className={style.playerWrapper}>
        <ReactPlayer
          ref={player}
          url={url}
          progressInterval={100}
          config={{
            file: {
              forceHLS: true,
              hlsVersion: '1.5.7',
              hlsOptions: {
                maxBufferLength: MAX_BUFFER_LENGTH_SECONDS,
                maxBufferSize: MAX_BUFFER_SIZE_BYTES,
                startPosition: startOffsetWithContext,
                maxFragLookUpTolerance: 0,
                xhrSetup: (xhr: XMLHttpRequest) => {
                  xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                },
              },
            },
          }}
          onEnded={() => {
            setPlaying(false)
            setIsEndReached(true)
            player.current?.seekTo(startOffsetWithContext, 'seconds')
          }}
          playing={playing}
          onProgress={handleProgress}
          onBuffer={() => {
            setBuffering(true)
          }}
          onSeek={() => {}}
          onBufferEnd={() => {
            setBuffering(false)
          }}
          onReady={() => {
            setBuffering(false)
          }}
          onDuration={(duration) => {
            setDuration(duration)
          }}
          onError={console.error}
        />
      </div>
      <div
        className={classNames(
          style.overlay,
          !playing ? style.overlayBg : undefined
        )}
      >
        <div
          className={classNames(
            style.controls,
            playing && !buffering ? style.playing : ''
          )}
        >
          {playing && buffering ? (
            <ProgressBar light={true} />
          ) : (
            <div className={style.iconContainer}>
              {showSkipButton && (
                <Icon
                  name={'SkipPrevious'}
                  version={Version.v2}
                  size={IconSizes.large}
                  className={classNames(
                    style.icon,
                    scenarios.some((v) => playedSecs > v.endOffset)
                      ? ''
                      : style.disabled
                  )}
                  onClick={onSkipBackward}
                />
              )}
              <Icon
                name={playing ? 'Pause' : shouldReplay ? 'Replay' : 'Play'}
                version={Version.v2}
                size={IconSizes.large}
                className={style.icon}
                onClick={togglePlayer}
              />
              {showSkipButton && (
                <Icon
                  name={'SkipNext'}
                  version={Version.v2}
                  size={IconSizes.large}
                  className={classNames(
                    style.icon,
                    scenarios.some((v) => v.startOffset > playedSecs)
                      ? ''
                      : style.disabled
                  )}
                  onClick={onSkipForward}
                />
              )}
            </div>
          )}
          {(playing || playedSecsWithOffset > 0) && (
            <>
              <div className={style.progress}>
                <div
                  ref={lineRef}
                  className={style.progressBar}
                  onClick={onLineClick}
                  style={{
                    background: maxDuration
                      ? `${scenarioGradient}, linear-gradient(to right, #fff ${
                          (playedSecsWithOffset * 100) / maxDuration
                        }%, transparent 0px), #a19f9e`
                      : '',
                  }}
                >
                  <div
                    className={style.marker}
                    style={{
                      left: `${(playedSecsWithOffset * 100) / maxDuration}%`,
                    }}
                  />
                </div>
              </div>
              <Typography
                className={style.startTime}
                type={TypographyTypes.body}
                size={TypographySizes.extraSmall}
                version={Version.v2}
                color="color-neutral-100"
              >
                {toHoursMinutesSecondsFromSeconds(
                  Math.floor(playedSecsWithOffset),
                  false
                )}
              </Typography>
              <Typography
                className={style.endTime}
                type={TypographyTypes.body}
                size={TypographySizes.extraSmall}
                version={Version.v2}
                color="color-neutral-100"
              >
                {toHoursMinutesSecondsFromSeconds(maxDuration, false)}
              </Typography>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(GridViewPlayer)
