import React, { memo, useEffect, useRef, useState } from 'react'
import style from './style.less'
import Badge from '../Badge'
import { BadgeType } from '../Badge/Badge'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import FilterMenuTag from '../FilterMenu/FilterMenuTag'
import { useClickOutside } from '../../customHooks/useClickOutside'
import { Tag, useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { RANGE_NUM_REGEX } from '../FilterMenu/consts'

export interface SearchQueryTagProps {
  tagKey: string
}

const formatTagValue = (tag: string) => {
  const splitTag = tag.split(' ')
  const values = splitTag[2]?.split(',')
  splitTag[2] = `${values?.length} values`
  const formated = values?.length > 2 ? splitTag.join(' ') : tag
  return formated.replace(' x-y ', ' = ')
}

const SearchQueryTag = ({ tagKey }: SearchQueryTagProps) => {
  const tagRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const [tag, setTag] = useState<Tag | null>()
  const [open, setOpen] = useState(false)
  const [createdRef, setCreatedRef] = useState<HTMLDivElement | null>(null)
  const [operatorsRef, setOperatorsRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    tag &&
      setOpen(tag.value.split(' ').filter((v: string) => v !== '').length === 2)
  }, [tag])

  useEffect(() => {
    setTag(tags?.filter((tag) => tag.key === tagKey)[0])
  }, [tagKey, tags])

  useEffect(() => {
    ref && setCreatedRef(ref as any)
  }, [ref])

  useClickOutside(
    tagRef,
    (props) => {
      const [, operator, value] = props.tag.value.split(' ')
      setOpen(false)
      if (!value || (operator === 'x-y' && !value.match(RANGE_NUM_REGEX))) {
        props.tags &&
          props.update(props.tags.filter((t: Tag) => t.key !== props.tagKey))
      }
    },
    { tags, update, tagKey, tag },
    operatorsRef
  )

  return (
    tag && (
      <div
        className={style.tagContainer}
        ref={tagRef}
        onClick={() => {
          setOpen(true)
        }}
      >
        <Badge
          ref={ref}
          key={tagKey}
          type={BadgeType.grey}
          label={
            (tag.value as string).split(' ').filter((v) => v !== '').length > 2
              ? `${formatTagValue(tag.value)} ${tag.unit || ''}`
              : formatTagValue(tag.value)
          }
          icon={
            <Icon
              name={'Number'}
              version={Version.v2}
              className={style.hashIcon}
            />
          }
        />
        <FilterMenuTag
          tagKey={tagKey}
          open={open}
          ref={createdRef as any}
          setOperatorsRef={setOperatorsRef}
        />
      </div>
    )
  )
}

export default memo(SearchQueryTag)
