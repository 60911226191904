import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import {
  SessionData,
  getSessionById,
  forceStopSession,
  getActiveSessions,
} from '@yaak/components/services/api/api'
import style from './style.less'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import { getDriveType } from '../../helpers/devices'
import classNames from 'classnames'
import Button from '@yaak/components/src/Button/Button'
import WarningDialog from '@yaak/components/src/WarningDialog'
import { getReadableLessonTopic } from '../../helpers/lessonTopics'
import Icon from '@yaak/components/src/Icon'
import Tooltip from '@yaak/components/src/Tooltip'
import Badge from '@yaak/components/src/Badge'
import Divider from '@yaak/components/src/Divider'
import { IconSizes, IconTypes } from '@yaak/components/src/Icon/Icon'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import InfoBox from '@yaak/components/src/InfoBox'
import { InfoItemProps } from '@yaak/components/src/InfoBox/InfoBox'
import appStyle from '../../style.less'
import { toDuration } from '../../helpers/time'

interface DriveDetalsPageProps {
  token: string
}

type mappingType = {
  [key: number]: any
}

const statusMapping: mappingType = {
  0: {
    style: style.defaultItemIcon,
    icon: (
      <Icon
        className={style.caretDown}
        name={'ArrowDownward'}
        type={IconTypes.regular}
      />
    ),
    text: 'Uploading',
  },
  1: {
    style: style.inProgressItem,
    icon: <Icon name={'ArrowForward'} />,
    text: 'Uploaded',
  },
  2: {
    style: style.inProgressItem,
    icon: <Icon name={'ArrowForward'} />,
    text: 'In progress',
  },
  3: {
    style: style.completeItem,
    icon: <Icon name={'Success'} />,
    text: 'Complete',
  },
  4: { style: style.errorItem, icon: <Icon name={'Error'} />, text: 'Error' },
}

const ratingMapping = [
  { style: style.lowRating, rating: 3 },
  { style: style.mediumRating, rating: 4 },
  { style: style.highRating, rating: 6 },
]

const formatDate = (date: string) => {
  const parsedDate = new Date(Date.parse(date))
  const dayMonth = parsedDate.toLocaleDateString('en-GB', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
  parsedDate.getDay().toFixed(2)
  const time = `${parsedDate
    .getHours()
    .toString()
    .padStart(2, '0')}:${parsedDate
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${parsedDate.getSeconds().toString().padStart(2, '0')}`
  return `${dayMonth}, ${time}`
}

const getFullUserName = ({
  firstname,
  lastname,
}: {
  firstname: string
  lastname: string
}) => `${firstname} ${lastname}`

const DevicePage: React.FunctionComponent<DriveDetalsPageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [drive, setDrive] = useState<SessionData>()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false)

  const navigate = useNavigate()

  const { driveId } = useParams()

  useEffect(() => {
    const fetchDrive = async () => {
      const [drive, activeSessions] = await Promise.all([
        getSessionById({ token, id: driveId || '', onAlert: setShowToast }),
        getActiveSessions({ token, onAlert: setShowToast }),
      ])
      setDrive(drive)
      setInProgress(
        activeSessions.data.some((session) => session.id === drive.id)
      )
    }

    token && fetchDrive()
  }, [token])

  const tags = (drive: SessionData) => {
    return (
      <div className={style.tagContainer}>
        {inProgress ? (
          <Badge label={'In progress'} icon={<Icon name={'ArrowForward'} />} />
        ) : (
          <Badge label={'Complete'} icon={<Icon name={'Success'} />} />
        )}
        <Badge
          type={BadgeType.grey}
          label={getDriveType(drive.driveType)}
          icon={<Icon name={'SteeringWheel'} />}
        />
        <Badge
          type={BadgeType.grey}
          label={drive.partner?.name || ''}
          icon={<Icon name={'Partner'} />}
        />
      </div>
    )
  }

  const onForceStopDrive = () => {
    setOpenWarningDialog(false)
    forceStopSession({ token, sessionId: driveId!, onAlert: setShowToast })
    setInProgress(false)
  }

  const onCopy = () => {
    navigator.clipboard.writeText(drive!.id)
    setShowToast({ text: `ID Copied`, type: ToastTypes.success })
  }

  return (
    <div className={appStyle.page}>
      {drive === undefined ? (
        <ProgressBar />
      ) : (
        <div className={style.container}>
          <div className={style.header}>
            <div className={style.headerInfo}>
              <Breadcrumbs
                first={{
                  text: 'Drives',
                  url: '/drives',
                }}
                second={{
                  text: formatDate(drive.startTimestamp),
                }}
              />
              <div className={style.headerSessionName}>
                <Typography type={TypographyTypes.headline} size="small">
                  {formatDate(drive.startTimestamp)}
                </Typography>
                <Tooltip text={'Copy drive ID'} position={'top'}>
                  <Icon
                    name={'Copy'}
                    size={IconSizes.large}
                    onClick={onCopy}
                    className={style.icon}
                  />
                </Tooltip>
              </div>
              {tags(drive)}
            </div>
            <Button
              className={style.forceStopButton}
              onClick={() => setOpenWarningDialog(true)}
              text="Force stop"
              disabled={!inProgress}
            ></Button>
          </div>
          <Divider />
          <div className={style.content}>
            <BasicInfoBox drive={drive} navigate={navigate} />
            <DataProcessingBox drive={drive} />
            <div className={style.box}>
              <HardwareBox drive={drive} navigate={navigate} />
              <TechBox drive={drive} />
            </div>
            {getDriveType(drive.driveType) === 'Student' ? (
              <LessonSummaryBox drive={drive} />
            ) : undefined}
          </div>
        </div>
      )}
      <WarningDialog
        isOpen={openWarningDialog}
        onCancel={() => setOpenWarningDialog(false)}
        onSubmit={onForceStopDrive}
        buttons={{
          cancel: 'Cancel',
          submit: 'Fore stop',
        }}
        dialogTitle={''}
        dialogContentText={'Are you sure you want to force stop this drive?'}
      />
    </div>
  )
}

const BasicInfoBox: React.FunctionComponent<{
  drive: SessionData
  navigate: NavigateFunction
}> = ({ drive, navigate }) => (
  <InfoBox
    label="Basic info"
    items={
      [
        {
          key: drive.id,
          label: 'Driver',
          tooltip: drive.driver ? 'View user profile' : undefined,
          text: drive.driver ? getFullUserName(drive.driver) : '—',
          icon: (
            <div className={style.avatar}>
              <img src={drive.driver?.profileImageURL} alt={'avatar'} />
            </div>
          ),
          click: () => {
            drive.driver && navigate(`/users/${drive.driver.id}`)
          },
        },
        drive.instructor
          ? {
              key: drive.id,
              label: 'Instructor',
              tooltip: 'View user profile',
              text: getFullUserName(drive.instructor),
              icon: (
                <div className={style.avatar}>
                  <img src={drive.instructor.profileImageURL} alt={'avatar'} />
                </div>
              ),
              click: () => {
                navigate(`/users/${drive.instructor?.id}`)
              },
            }
          : undefined,
        {
          key: drive.id,
          label: 'Duration',
          text: drive.endTimestamp
            ? toDuration(drive.startTimestamp, drive.endTimestamp)
            : '—',
          icon: (
            <div className={style.defaultItemIcon}>
              <Icon name={'Duration'} />
            </div>
          ),
        },
      ].filter((item) => item !== undefined) as InfoItemProps[]
    }
  />
)

const DataProcessingBox: React.FunctionComponent<{ drive: SessionData }> = ({
  drive,
}) => (
  <InfoBox
    label="Data processing"
    items={[
      {
        key: drive.id,
        label: 'Metadata',
        text: statusMapping[drive.metadataStatus].text,
        icon: (
          <div
            className={classNames(
              style.status,
              statusMapping[drive.metadataStatus].style
            )}
          >
            {statusMapping[drive.metadataStatus].icon}
          </div>
        ),
      },
      {
        key: drive.id,
        label: 'Raw video',
        text: statusMapping[drive.fullVideoStatus].text,
        icon: (
          <div
            className={classNames(
              style.status,
              statusMapping[drive.fullVideoStatus].style
            )}
          >
            {statusMapping[drive.fullVideoStatus].icon}
          </div>
        ),
      },
      {
        key: drive.id,
        label: '360° video',
        text: statusMapping[drive.videoStatus].text,
        icon: (
          <div
            className={classNames(
              style.status,
              statusMapping[drive.videoStatus].style
            )}
          >
            {statusMapping[drive.videoStatus].icon}
          </div>
        ),
      },
    ]}
  />
)

const HardwareBox: React.FunctionComponent<{
  drive: SessionData
  navigate: NavigateFunction
}> = ({ drive, navigate }) => (
  <InfoBox
    label="Vehicle & hardware"
    items={[
      {
        key: drive.id,
        label: 'VIN',
        tooltip: drive.vin ? 'View vehicle profile' : undefined,
        text: drive.vin || '—',
        icon: (
          <div className={style.defaultItemIcon}>
            <Icon name={'Car'} />
          </div>
        ),
        click: () => {
          drive.vin && navigate(`/vehicles/${drive.vin}`)
        },
      },
      {
        key: drive.id,
        tooltip: 'View kit profile',
        label: 'Dongle ID',
        text: drive.dongleId,
        icon: (
          <div className={style.defaultItemIcon}>
            <Icon name={'Car'} />
          </div>
        ),
        click: () => {
          navigate(`/kits/details/${drive.dongleId}`)
        },
      },
      {
        key: drive.id,
        label: 'License plate',
        text: drive.licensePlate || '-',
        icon: (
          <div className={style.defaultItemIcon}>
            <Icon name={'LicensePlate'} />
          </div>
        ),
        click: () => {
          navigate(`/kits/details/${drive.dongleId}`)
        },
      },
    ]}
  />
)

const TechBox: React.FunctionComponent<{
  drive: SessionData
}> = ({ drive }) => (
  <InfoBox
    label="Tech specs"
    items={[
      {
        key: drive.id,
        label: 'Canonical drive name',
        text: drive.canonicalName,
        icon: (
          <div className={style.defaultItemIcon}>
            <Icon name={'Language'} />
          </div>
        ),
      },
      {
        key: drive.id,
        label: 'Video bitrate limit',
        text: drive.bitRateKbps?.toString() || '-',
        icon: (
          <div className={style.defaultItemIcon}>
            <Icon name={'VideoCamera'} />
          </div>
        ),
      },
    ]}
  />
)

const LessonSummaryBox: React.FunctionComponent<{ drive: SessionData }> = ({
  drive,
}) => (
  <InfoBox
    label="Lesson summary"
    headerIcon={<RatingBox rating={drive.rating} />}
    items={[
      {
        key: drive.id,
        label: 'Incidents',
        text: drive.incidentCount.toString(),
        icon: (
          <div className={classNames(style.status, style.errorItem)}>
            <Icon name={'Error'} />
          </div>
        ),
      },
      {
        key: drive.id,
        label: 'Bookmarks',
        text: drive.bookmarkCount.toString(),
        icon: (
          <div className={classNames(style.status, style.bookmarkItem)}>
            <Icon name={'Bookmark'} />
          </div>
        ),
      },
      {
        key: drive.id,
        label: `Lesson topics (${drive.lessonTopics?.length})`,
        text: drive.lessonTopics
          ? drive.lessonTopics
              ?.map((topic) => getReadableLessonTopic(topic))
              .join(', ')
          : '-',
        icon: (
          <div className={style.defaultItemIcon}>
            <Icon name={'CheckList'} />
          </div>
        ),
      },
    ]}
  />
)

const RatingBox: React.FunctionComponent<{ rating: number | null }> = ({
  rating,
}) => {
  return (
    <div
      className={classNames(
        style.rating,
        rating !== null && ratingMapping.find((rm) => rating < rm.rating)?.style
      )}
    >
      <Tooltip text={`Rating: ${rating}`} className={style.tooltip}>
        <Icon name={'Star'} />
        <Typography type={TypographyTypes.label}>
          {rating === null ? '-' : `${rating}`}
        </Typography>
      </Tooltip>
    </div>
  )
}

export default DevicePage
