// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__container--_uvnS {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n.style__loadingBar--tT2Ah {\n  animation: style__load--QvIxv 1s infinite linear;\n}\n@keyframes style__load--QvIxv {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://./../components/src/ProgressBar/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAHJ;AAHA;EAUI,gDAAA;AAJJ;AAOE;EACE;IACE,uBAAA;EALJ;EAOE;IACE,yBAAA;EALJ;AACF","sourcesContent":["@import '../colors';\n@import '../spacing';\n\n:local {\n  .container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n  }\n\n  .loadingBar {\n    animation: load 1s infinite linear;\n  }\n\n  @keyframes load {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style__container--_uvnS",
	"loadingBar": "style__loadingBar--tT2Ah",
	"load": "style__load--QvIxv"
};
export default ___CSS_LOADER_EXPORT___;
