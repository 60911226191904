import React, { ReactElement, useContext, useEffect, useState } from 'react'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import style from './style.less'
import Divider from '@yaak/components/src/Divider/Divider'
import Icon from '@yaak/components/src/Icon'
import Badge from '@yaak/components/src/Badge'
import Button from '@yaak/components/src/Button'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import {
  getDevice,
  Device,
  startDevice,
  updatePairingRequest,
  getPairingRequests,
  PairingRequest,
} from '@yaak/components/services/api/api'
import appStyle from '../../style.less'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'
import { useNavigate, useParams } from 'react-router-dom'
import WarningDialog from '@yaak/components/src/WarningDialog'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import InfoBox from '@yaak/components/src/InfoBox'

interface KitDetailsPageProps {
  token: string
}

const CarIcon: React.FunctionComponent = () => (
  <div className={style.defaultItemIcon}>
    <Icon name={'Car'} />
  </div>
)

const tags = (device: Device) => {
  return (
    <div className={style.tagContainer}>
      <Badge label={device.status} withDot />
      {device.kitType && (
        <Badge label={device.kitType} icon={<Icon name={'Device'} />} />
      )}
    </div>
  )
}

const KitDetailsPage: React.FunctionComponent<KitDetailsPageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const [device, setDevice] = useState<Device | null>(null)
  const [unpairing, setUnpairing] = useState<Device | null>(null)
  const [powerON, setPowerOn] = useState<string | null>(null)
  const [pairingRequest, setPairingRequest] = useState<PairingRequest>()

  const { dongleId } = useParams()

  const navigate = useNavigate()

  const fetchDevice = async () => {
    const device = await getDevice({
      token,
      dongleId,
      onAlert: setShowToast,
    })
    setDevice(device)
  }

  useEffect(() => {
    token && fetchDevice()
  }, [dongleId, token])

  useEffect(() => {
    const fetchPairingRequests = async () => {
      const result = await getPairingRequests({
        limit: 1000,
        token,
        onAlert: setShowToast,
      })

      setPairingRequest(
        result.data.filter(
          (pairingRequest) => pairingRequest.kitId === dongleId
        )[0]
      )
    }
    token && fetchPairingRequests()
  }, [dongleId, token])

  const onCopy = () => {
    if (dongleId) {
      navigator.clipboard.writeText(dongleId)
      setShowToast({ text: `ID Copied`, type: ToastTypes.success })
    }
  }

  const powerONKit = async (dongleId: string | null) => {
    setPowerOn(null)
    dongleId && (await startDevice({ token, dongleId, onAlert: setShowToast }))
  }

  const updatePairing = async (newStatus: string, id: string) => {
    await updatePairingRequest({
      token,
      id,
      status: newStatus,
      onAlert: setShowToast,
    })
    setUnpairing(null)
  }

  return (
    <div className={appStyle.page}>
      {dongleId && (
        <Breadcrumbs
          first={{
            text: device?.pairedVehicleVIN ? 'Kits: Paired' : 'Kits: Unpaired',
            url: device?.pairedVehicleVIN ? '/kits/paired' : '/kits/unpaired',
          }}
          second={{
            text: dongleId,
          }}
        />
      )}
      {device && (
        <>
          <div className={style.header}>
            <div className={style.headerName}>
              <Typography type={TypographyTypes.headline} size="small">
                {dongleId}
              </Typography>
              <Icon
                name={'Copy'}
                size={IconSizes.large}
                onClick={onCopy}
                className={style.icon}
              />
            </div>
            <div className={style.headerName}>
              {device.mqttEnabled && (
                <Button
                  onClick={() => {
                    setPowerOn(device.dongleId)
                  }}
                  icon={<Icon name={'PowerOnOff'} />}
                  text={'Turn on'}
                  secondary
                />
              )}
              {device.pairedVehicleVIN && (
                <Button
                  onClick={() => {
                    setUnpairing(device)
                  }}
                  icon={<Icon name={'LinkOff'} />}
                  text={'Unpair'}
                  secondary
                />
              )}
            </div>
          </div>
          {tags(device)}
          <Divider />
          <div className={style.content}>
            <DrivesOverview
              token={token}
              withHeader={false}
              withKit={false}
              filter={{
                dongleId: device.dongleId,
              }}
              fixedColumns={3}
            />
            <div className={style.infoContainer}>
              <InfoBox
                label={'Vehicle'}
                items={[
                  {
                    key: device.dongleId,
                    label: 'VIN',
                    text: device.pairedVehicleVIN,
                    icon: <CarIcon />,
                    click: () =>
                      navigate(`/vehicles/${device?.pairedVehicleVIN}`),
                  },
                  {
                    key: device.dongleId,
                    label: 'Dongle ID',
                    text: device.dongleId,
                    icon: <CarIcon />,
                  },
                  {
                    key: device.dongleId,
                    label: 'Licence plate',
                    text: device.licensePlate,
                    icon: <CarIcon />,
                  },
                ]}
              />
              <InfoBox
                label={'Tech specs'}
                items={[
                  {
                    key: device.dongleId,
                    label: 'Last status update',
                    text: device.lastStatusUpdate,
                    icon: (
                      <div className={style.defaultItemIcon}>
                        <Icon name={'Update'} />
                      </div>
                    ),
                  },
                  {
                    key: device.dongleId,
                    label: 'IP address',
                    text: device.ipAddress,
                    icon: (
                      <div className={style.defaultItemIcon}>
                        <Icon name={'Location'} />
                      </div>
                    ),
                  },
                  {
                    key: device.dongleId,
                    label: 'MQTT',
                    text: device.mqttEnabled ? 'Enabled' : 'Not enabled',
                    icon: device.mqttEnabled ? (
                      <div className={style.successItemIcon}>
                        <Icon name={'Success'} />
                      </div>
                    ) : (
                      <div className={style.errorItemIcon}>
                        <Icon name={'Cancel'} />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
      <WarningDialog
        isOpen={powerON !== null}
        dialogContentText={`Are you sure you want to turn on kit ${powerON}?`}
        dialogTitle={''}
        onSubmit={() => powerONKit(powerON)}
        onCancel={() => setPowerOn(null)}
        buttons={{
          cancel: 'Cancel',
          submit: 'Turn on kit',
        }}
      />
      <WarningDialog
        isOpen={unpairing !== null}
        dialogContentText={`Are you sure you want to unpair kit ${unpairing?.dongleId}?`}
        dialogTitle={''}
        onSubmit={async () => {
          pairingRequest &&
            unpairing?.dongleId &&
            (await updatePairing('UNPAIRED', pairingRequest?.id))
          await fetchDevice()
        }}
        onCancel={() => setUnpairing(null)}
        buttons={{
          cancel: 'Cancel',
          submit: 'Unpair kit',
        }}
      />
    </div>
  )
}

export default KitDetailsPage
