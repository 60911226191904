import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import style from './style.less'
import {
  Scenario,
  SearchSessionData,
  SessionData,
} from '@yaak/components/services/api/api'
import SortingSelect, { ISortingSelectProps } from './SortingSelect'
import { onScroll } from '../Grid/helpers'
import GridItem from './GridItem'
import Toast from '../Toast'
import { toastType } from '../Toast/Toast'
import { IGridSelection } from '../Grid/GridSelection'

interface IGridViewProps extends ISortingSelectProps {
  token: string
  sessionsData: SessionData[] | Scenario[] | SearchSessionData[]
  fetchData?: () => void
  rowSelection: IGridSelection
}
const GridView = ({
  token,
  sessionsData,
  onSort,
  sortBy,
  sortOrder,
  rowSelection,
  fetchData,
}: IGridViewProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [toast, setShowToast] = useState<toastType | null>(null)

  useEffect(() => {
    if (fetchData) {
      const onScrollFun = () =>
        onScroll(
          fetchData,
          loadingMore,
          setLoadingMore,
          '#gridViewContainer',
          '.gridViewItem'
        )
      window.addEventListener('scroll', onScrollFun, true)
      return () => window.removeEventListener('scroll', onScrollFun)
    }
  }, [sessionsData?.length, fetchData, loadingMore])

  const onItemChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { selectedIds, onSelection } = rowSelection
    const { value: id, checked } = e.target
    const newIds = checked
      ? [...selectedIds, id]
      : selectedIds.filter((i) => i !== id)
    onSelection(newIds)
  }

  return (
    <div className={style.container} id="gridViewContainer">
      <SortingSelect onSort={onSort} sortBy={sortBy} sortOrder={sortOrder} />
      <div className={style.gridContainer}>
        {sessionsData?.map((session) => {
          const id =
            (session as SessionData).id ||
            `${(session as Scenario).sessionId}&${
              (session as Scenario).startOffset
            }`
          return (
            <GridItem
              key={id}
              session={session}
              token={token}
              setShowToast={setShowToast}
              checked={rowSelection.selectedIds.includes(id)}
              onCheck={onItemChange}
            />
          )
        })}
      </div>
      {toast && <Toast toast={toast} setShowToast={setShowToast} />}
    </div>
  )
}

export default memo(GridView)
